import React,{useEffect, useState, useContext} from "react"
 import { TimelineContext } from "../../helpers/Context"; 
import { isMobile } from "react-device-detect";

const NSMultiSelector = ({options:optionsProps,oneSelection, onCallBack, initial,className,customInitial, customColorActive,customColor}) =>{
 
    const [ options , setOptions ] = useState( optionsProps || [] )
    const [ selectedOptions , setSelectedOptions ] = useState( initial || []  )

    const handlerOnClick = ( e ) => {
        const element = e.target.value
        const have = exist(element)
        if(have){
            if( oneSelection ){
                setSelectedOptions([...selectedOptions.filter(a => a != element)])
            } else {
                setSelectedOptions([...selectedOptions.filter(a => a != element)])

            }
        } else { 
            if( oneSelection ){
                setSelectedOptions([ element])
            } else {
                setSelectedOptions([...selectedOptions, element])
                
            }
        }
    }
    
    const exist = (item) => {
        if(selectedOptions.find((a)=> a === item)){
            return true
        }
        return false
    }
     useEffect(()=>{ 
        if(onCallBack){  
            onCallBack(selectedOptions) 
        } 
     },[selectedOptions]) 
    
    return (
    <div className={"w-full text-xl " }>
        <div className={"overflow-auto " + className}> 
            {options?.map( ( item, index ) => { 
                const current = exist(item)
                const key = `opt-${index}-${item}`
                return  (
                    <div htmlFor={key} 
                        style={{
                            background: 
                                exist(item)?
                                        index < parseInt(customInitial) ?
                                            customColorActive? 
                                            customColorActive : 
                                            "#2aac9c":
                                   "#2aac9c" : 
                                        index < parseInt(customInitial) ?
                                            customColor? 
                                            customColor : 
                                        "#eaf7f5":
                                     "#eaf7f5",
                            color: current ? "white" : "black"
                        }} 
                        className="flex gap-4 py-3  my-1 w-full rounded p-3 min-h-[60px] text-lg items-center cursor-pointer" 
                        key={key} 
                        >
                        <input  type="checkbox" 
                            style={{
                                fontSize: isMobile ? '14px' : '22px' ,
                                accentColor: current ? "#000" : "#2aac9c", 
                            }}  
                            className="h-[30px] w-[30px] accent-[#fff]  checked:bg-[red]" 
                            id={key}  
                            value={item}  
                            checked={current}
                            onChange={handlerOnClick}  
                            />
                        <label style={styles.label} htmlFor={key}>
                                {item}
                        </label>
                    </div>
                ) 
            })} 
        </div> 
    </div>) 
}

export default NSMultiSelector

const styles = {
    label:{ 
        fontSize: isMobile ? '14px' : '18px', 
        lineHeight: isMobile ? '18px' : '22px',
        cursor: 'pointer',
        width: '100%'
    }
}