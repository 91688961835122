/**
* 
* @author Juan Rico
* @component NSSwitcher
* @description  un boton tipo toogle personalizable
* normalmente usado para funciones boleanas con callback
*/

//#region 
// Importaciones necesarias para el componente NSTimer
import React,{useEffect, useState} from "react"  // Importa React y hooks.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' //importa componentes de fontawesome
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons' //importa iconos de fontawesome
import { PRIMARY_COLOR } from "../../../helpers/Constants" //importar constantes 
//#endregion


const NSSwitcher = ({
    title: titleProps, //Perzonaliza el titulo del componente
    titleActive: titleActiveProps, //Habilita la visualizacion del titulo
    subtitle: subtitleProps, //Perzonaliza el texto del subtitulo
    subtitleActive: subtitleActiveProps, //Habilita la visualizacion del subtitulo
    initialState, //Perzonaliza el estado inicial del componente
    onClick, //Accion callback al presionar CLICK
    forceDisable, //Forzar la desactivacion la accion de este componente
    forceStop //Forzar DETENER las acciones en background
}) =>{

//#region ############## VARIABLES DE ESTADO GENERALES ##############/
    /**
     * Titulo del componente
     * @type {String}
     * @default titleProps - mantiene el valor del titulo del componente  
    */
    const [title, setTitle] = useState( titleProps  || '') 

    /**
     * Subtitulo del componente
     * @type {String}
     * @default subtitleProps - mantiene el valor del subtitulo del componente   
    */
    const [subtitle, setSubtitle] = useState(subtitleProps  || '') 

    /**
     * Estado inicial del componente
     * @type {boolean}
     * @default initialState - perzaonaliza el estado inicial del componente
    */
    const [active, setActive] = useState(initialState)  
//#endregion

//#region ############## FUNCIONES HANDLE BASICAS ##############//

    /**
	 * Manejador del estado active
	 * @function handlerCheck 
     * Se utiliza para intercambiar el estado de la variable active 
     * de tal manera que pueda activar o desactivar la opcion y accionar 
     * el callback onClick en caso de que se pase la prop
	 */ 
    const handlerCheck = () => {
        if(forceStop) return
        if(forceDisable) return
        setActive(!active) 
        if(onClick){
            onClick(!active)
        }
    }
//#endregion

//#region ############## USEEFFECTS ##############//
    useEffect(()=>{
        if(forceStop) return
        if(forceDisable) return
        if( active ){
            if(subtitleActiveProps){ 
                setSubtitle(subtitleActiveProps)
            }
            if(titleActiveProps){ 
                setTitle(titleActiveProps)
            } 
        } else {
            setSubtitle(subtitleProps)
            setTitle(titleProps)
        }

    },[active])
//#endregion   
    return (<>
        <div 
            style={{ opacity: forceDisable ? '50%' : '100%'}}
            className="flex  items-center h-[40px] cursor-pointer" 
            onClick={handlerCheck}
        >
            <div className="flex p-1 items-center gap-2"> 
                <div style={{backgroundColor:active?PRIMARY_COLOR:'white',transition:'ease-in'}} className={`flex ${active?`justify-end text-white`:'justify-start text-black'}   rounded-full bg-[#fff] border p-1 w-[40px] `}>
                    <FontAwesomeIcon icon={active?faCircleCheck :faCircleXmark} /> 
                </div>
                <div className="flex flex-col text-sm p-1 items-start">
                    <span className="font-[600]">
                        {title}
                    </span> 
                    {
                        subtitle && 
                        <span 
                            className="text-[10px] font-[600]"
                            style={{color:active?PRIMARY_COLOR:'#0005',}}
                        >
                            {subtitle}
                        </span>
                    }
                </div>
            </div>
        </div>
    </>) 
}

export default NSSwitcher