import React, { useState, useEffect, useContext } from 'react'
import './ovace.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-tabs/style/react-tabs.css' 
import Header from '../../components/Header/Header'
import { isMobile,isDesktop,isTablet } from 'react-device-detect' 
import NSSwitcher from '../../components/NSButton/Types/Switcher'
import NSBoolean from '../../components/NSButton/Types/Boolean' 
import NSTimer from '../../components/NSButton/Types/Timer'
import NSCounter from '../../components/NSButton/Types/Counter'
import NSIndicator from '../../components/NSButton/Types/Indicator'
import NSSimpleButton from '../../components/NSButton/Types/SimpleButton' 
import NSToogle from '../../components/NSButton/Types/Toogle'
import {   
    iconAvisaEquipo,  
    iconCompresiones,  
    iconNotas,  
    iconTos,
    iconNino,
    logoOvace,
    
} from '../../helpers/ImagesCache'
import DialogPediatrico from '../../components/DialogPediatrico/DialogPediatrico'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { GlobalContext,TimelineContext } from '../../helpers/Context' 
import { TimelineDefault } from '../../helpers/Constants'
import { Link } from 'react-router-dom'

import DialogDownNotes from '../../components/DialogDownNotes/DialogDownNotes'
import axios from 'axios';
import { BASEURLAPI } from '../../helpers/Constants';

const Ovace = () => { 
    const contextGlobal = useContext( GlobalContext ); 
    const ctxTimeline = useContext( TimelineContext );
    const [ForceStopCase, setForceStopCase] = useState(false)
    const [isStarted, setIsStarted] = useState(false) 
    const [canGetReport, setCanGetReport] = useState(false) 
    const [timerCounter, setTimerCounter] = useState(0) 
    const [isTryingToStop, setTsTryingToStop] = useState(false) 
        
    const [isPopupActive, setIsPopupActive] = useState(true) 
    const [isPopupNotasActive, setIsPopupNotasActive] = useState(false) 
    const [pediatric, setPediatric] = useState(false) 

    const [complete, setComplete] = useState(false) 
    const [conscious, setConscious] = useState(true) 
    const [notas, setNotas] = useState('')
    const [userId, setUserId ] = useState(2)
    
    const SaveInforme = () => { 
        const url = `${BASEURLAPI}/createCase`
        const formData = new FormData()
        formData.append('userId',userId)
        formData.append('name',`TESTCASE-${+new Date()} (OVACE)` )
        formData.append('timeline',JSON.stringify(ctxTimeline) )
        formData.append('duration',0)
        axios.post(url, formData )
        .then((response)=>{
            if(response.status == 200){
                console.log(response.data)
            }
            //setIsLoading(false)
        })
        .catch((error)=>{
            console.log('hubo un error',error)
            //setIsLoading(false)
        })
    }
    useEffect(()=>{ 
        ctxTimeline.ECG = ''
        ctxTimeline.Notas = ''
        ctxTimeline.CausaH = '' 
        ctxTimeline.causaT = '' 
        ctxTimeline.Tecnicas = '' 
        ctxTimeline.Farmacos = ''  
    },[])
    useEffect(()=>{ 
        
        setNotas(ctxTimeline.Notas)  

    },[ctxTimeline.Notas])

    useEffect(()=>{
        if(canGetReport){

        }
    },[canGetReport])

    useEffect(()=>{
        if(timerCounter > 0){
            setCanGetReport(true)
        } else {
            setCanGetReport(false)
        }
    },[isStarted])

    useEffect(() => {
		ctxTimeline.type = 'ovace'
	}, [])

    const handlerStopDialog = () => setTsTryingToStop(!isTryingToStop)
    const handlerForceStopCase = (value) => setForceStopCase(value)
    const handlerStart = () => setIsStarted(true)
    const handlerStop = () => setIsStarted(false) 
    const handlerPediatric = () => {
        setIsPopupActive(false)
        setPediatric(true)
        //realizar la funcion de activar compresiones y ventilaciones
    }
    const handlerCloseDialog = () => {
        setIsPopupActive(false) 
    }

    const handlerNotasDialog = () => {
        setIsPopupNotasActive(!isPopupNotasActive) 
    }

    useEffect(()=>{
       // ctxTimeline.name = 'TimelineDefault'
       ctxTimeline.history = []
    },[])

    return ( <>
         <div  style={styles.main} >
            <Header options={{}} minimal={true} logo={logoOvace}  />
            
            
            <div  className='flex gap-8 my-3 lg:my-2'>
                <NSTimer   
                    milliseconds={true}
                    options={
                        { 
                            titleOn: 'Parar caso',
                            titleOff : 'Iniciar caso'
                        }}
                    onStopTimer={(value)=>{ 
                        handlerForceStopCase(true)
                        ctxTimeline.startCase = value?.startAt 
                        ctxTimeline.stopCase =  value?.stopAt 
                        ctxTimeline.history.push({
                            time: value?.stopAt,
                            title:'Fin del Caso',
                            object:'stopCase'
                        })
                        handlerStop()
                        handlerStopDialog()
                    }}
                    onStartTimer={(value)=>{  
                        ctxTimeline.startCase = value?.startAt 
                        ctxTimeline.history.push({
                            time: value?.startAt,
                            title:'Inicio de Caso',
                            object:'startCase'
                        })
                        handlerStart()
                    }} 
                />,
                <NSTimer  
                    milliseconds={true}
                    options={
                        { 
                            titleOn:'RCE',
                            titleOff:'Inicio PCR'
                        }} 
                    onStopTimer={(value)=>{ 
                        ctxTimeline.RCE.push(
                            {
                            start: value?.startAt,
                            stop: value?.stopAt
                            } 
                        ) 
                        ctxTimeline.history.push({
                            time: value?.stopAt,
                            title:'RCE',
                            object:'RCE'
                        })
                    }}
                    onStartTimer={(value)=>{  
                        ctxTimeline.PCR.push(
                            {
                            start: value?.startAt 
                            } 
                        ) 
                        ctxTimeline.history.push({
                            time: value?.startAt ,
                            title:'Inicio PCR',
                            object:'PCR'
                        })
                    }} 
                    forceDisable={!isStarted}
                    forceStop={ForceStopCase}
                /> 
            </div>
            <div  className='w-full'  style={styles.bodyContent} >
                <div className="p-2 w-full  h-full" style={styles.switcherSection}> 
                    <div className="w-10/12 flex justify-between w-full ">
                        <NSToogle 
                            options={{
                                titles:['Incompleta','Completa'],
                                initialState: complete ? 1 : 0
                            }}  
                            onClick={(value)=>{   
                               console.log(value)
                                if( value.active == 0 ){
                                    setComplete(false)
                                } else if( value.active == 1 ){
                                    setComplete(true)
                                }
                                ctxTimeline.complete = value.active == 1 ? true : false
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title:`Evaluacion ${ value.active ? 'Completa ' : 'Incompleta ' }`,
                                    object:'complete'
                                })
                            }} 
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSToogle 
                            options={{
                                titles:['Inconsciente','Consciente'],
                                initialState: conscious ? 1 : 0
                            }}  
                            onClick={(value)=>{   
                               console.log(value)
                                if( value.active == 0 ){
                                    setConscious(false)
                                } else if( value.active == 1 ){
                                    setConscious(true)
                                }
                                ctxTimeline.conscious = value.active == 1 ? true : false 
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title:`El paciente esta ${ value.active ? 'Consciente ' : 'Inconsciente ' }`,
                                    object:'conscious'
                                })
                            }} 
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                    </div>
                    <div className=" flex w-full flex-wrap gap-[2%] justify-center">
                        <NSBoolean 
                            title='Animar a toser'
                            icon={iconTos}
                            initialState={false} 
                            onClick={(value)=>{   
                                if(value == true){
                                    ctxTimeline.toser = +new Date()
                                }
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title:`${ value ? 'Activa' : 'Deshabilita' } Animar a toser`,
                                    object:'toser'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                            
                        />
                         
                        <NSBoolean  
                            title='Avisa equipo de emergencias'
                            icon={iconAvisaEquipo}
                            initialState={false} 
                            onClick={(value)=>{   
                                if(value == true){
                                    ctxTimeline.notifiesEmergencyTeam = +new Date()
                                }
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title:`${ value ? 'Activa' : 'Deshabilita' } Notificar equipo emergencia`,
                                    object:'notifiesEmergencyTeam'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                             
                        />
                        <NSCounter 

                            options={{
                                titleStart:'Golpes Espalda',
                                titleOff:'Golpes Espalda',
                                titleOn:'Golpes Espalda',
                                icon:null,
                                initialState:false,
                                onlyCounter:true,
                                type:'restart'
                            }}  
                            onStopTimer={(value)=>{ 
                                ctxTimeline.backBlows.push(
                                    {
                                     start: value?.startAt,
                                     stop: value?.stopAt
                                    } 
                                ) 
                                ctxTimeline.history.push({
                                    time: value?.stopAt,
                                    title:`Detiene Golpes Espalda`,
                                    object:'backBlows'
                                })
                            }} 
                            onStartTimer={(value)=>{
                                ctxTimeline.history.push({
                                    time: value?.startAt,
                                    title:`Inicia Golpes Espalda`,
                                    object:'backBlows'
                                })
                            }}
                            list = {ctxTimeline.backBlows}
                            forceDisable={ !complete || !isStarted} 
                            forceStop={ForceStopCase}
                        />
                        <NSCounter 
                            options={{
                                titleStart:'Compresiones abdominales',
                                titleOff:'Compresiones abdominales',
                                titleOn:'Compresiones abdominales',
                                icon:null,
                                initialState:false,
                                onlyCounter:true,
                                type:'restart'
                            }}  
                            onStopTimer={(value)=>{ 
                                ctxTimeline.compressionsAbs.push(
                                    {
                                     start: value?.startAt,
                                     stop: value?.stopAt
                                    } 
                                ) 
                                ctxTimeline.history.push({
                                    time: value?.stopAt,
                                    title:`Detiene Compresiones abdominales`,
                                    object:'compressionsAbs'
                                })
                            }} 
                            onStartTimer={(value)=>{
                                ctxTimeline.history.push({
                                    time: value?.startAt,
                                    title:`Inicia Compresiones abdominales`,
                                    object:'compressionsAbs'
                                })
                            }}
                            list = {ctxTimeline.compressionsAbs}
                            forceDisable={ !complete || !isStarted} 
                            forceStop={ForceStopCase}
                        />
                         
                         
                        <NSCounter 
                            options={{
                                titleStart:'Compresiones toracicas',
                                titleOff:'Compresiones toracicas',
                                titleOn:'Compresiones toracicas',
                                icon:iconNino,
                                initialState:false,
                                onlyCounter:true,
                                type:'restart'
                            }}  
                            onStopTimer={(value)=>{ 
                                ctxTimeline.compressionsThorax.push(
                                    {
                                     start: value?.startAt,
                                     stop: value?.stopAt
                                    } 
                                ) 
                                ctxTimeline.history.push({
                                    time: value?.stopAt,
                                    title:`Detiene Compresiones toracicas`,
                                    object:'compressionsThorax'
                                })
                            }} 
                            onStartTimer={(value)=>{
                                ctxTimeline.history.push({
                                    time: value?.startAt,
                                    title:`Inicia Compresiones toracicas`,
                                    object:'compressionsThorax'
                                })
                            }}
                            list = {ctxTimeline.compressionsThorax}
                            
                            forceDisable={ pediatric || !isStarted} 
                            forceStop={ForceStopCase}
                        /> 
                         
                         
                        <NSIndicator
                             options={{
                                title:'Notas',
                                icon:iconNotas,
                                values:  [
                                    {status: notas == '' ? false : true },
                                ] ,
                                minimal:true,
                                initialState:true
                            }}  
                            onClick={handlerNotasDialog}
                            forceDisable={ !isStarted} 
                            forceStop={ForceStopCase}
                        />   
                    </div>
                    <Link  to={'/basic'}  >
                        <NSSimpleButton 
                            title={'Inicia soporte vital basico'}
                            faIcon={faRightFromBracket}
                            iconPosition={'row-reverse'}
                            
                            initialState={canGetReport}
                            callBack={()=>{}} 
                            styles={{
                                width:'calc(99% - 200px',
                                textAlign:'center',
                                margin:100,
                                background:'#0000',
                                border:'solid 1px #2aac9c',
                                color:'#2aac9c' 
                            }}
                            
                        />  
                    </Link>
                </div>
            </div>
            <div className='z-10 h-[12vh] bg-[#2aac9cff] flex flex-col justify-center items-center w-full opacity-[100%]'>  
                
                <NSSimpleButton
                    title={'Informe' }
                     
                    initialState={canGetReport}
                    callBack={SaveInforme} 
                    
                />  
            </div>
        </div>

       
        <DialogPediatrico
                text={'El paciente es pediatrico o adulto?'}  
                callbackConfirm={handlerPediatric}
                callbackCancel={handlerCloseDialog}
                status={isPopupActive}
            />
             <DialogDownNotes 
                 text={'Notas'} 
                 icon={iconNotas} 
                 callbackConfirm = {()=>{ 
                    handlerNotasDialog()
                 }}
                 callbackCancel={handlerNotasDialog}
                 status={isPopupNotasActive} 
            
            />
        </>
    )
}
export default Ovace



const styles = {
    main:{
        maxWidth: isTablet ? '100%': isDesktop ? '1280px' : '700px',
        maxHeight:'100vh',
        position:'fixed',
        left:0,
        right:0,
        top:0,
        overflow:'hidden',
        display:'flex',
        flexDirection:'column',
        width:'100%', 
        justifyContent:'center',
        alignItems:'center' ,
        marginRight:'auto',
        marginLeft: 'auto'
       
    },
    bodyContent: { 
        display:'flex',
        zIndex: 0 ,
        background: '#eaf7f5',
        overflow: 'hidden',
        height:'100%',
        minHeight:'80vh'
    },
    switcherSection:{
        display:'flex', 
        alignItems:'center',
        flexDirection:'column',
        overflow: 'hidden' 
    },
    buttonsSeccion:{
        gap:isMobile?'2%':'1%',
        justifyContent:'center',
        marginBottom:isMobile?'200px':'0px',
        display:'flex',
        flexWrap: 'wrap',
        width: '100%',
        overflowY:'auto'
    },
    footer:{
        zIndex:1000,
        height:isMobile? '10%':'12%',
        background:'#2aac9cff',
        position:'fixed',
        bottom:0,
        left:0,
        right:0,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center' 
    }
}