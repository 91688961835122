import { useState, useEffect, useContext } from 'react';
import { isMobile } from "react-device-detect";
import DialogCalidadRcp from '../DialogCalidadRcp/DialogCalidadRcp'
import DialogNoTecnicas from '../DialogNoTecnicas/DialogNoTecnicas'
import axios from 'axios';
import { BASEURLAPI } from '../../helpers/Constants';
import { ContextoReset, GlobalContext, TimelineContext } from '../../helpers/Context'
import Loader from '../Loader/Loader';




const DialogMedirAmbas = ({ callbackConfirm, callbackCancel, status }) => {

    const ctxTimeline = useContext(TimelineContext);
    const [isOpen, setIsOpen] = useState(status)
    const [isPopUpNoTecnicas, setIsPopUpNoTecnicas] = useState(true)
    const [isPopUpCalidadRcp, setIsPopUpCalidadRcp] = useState(false)
    const [loadingText, setLoadingText] = useState('Guardando informe...')
    const [isLoading, setIsLoading] = useState(false)
    const [userId, setUserId] = useState(2)

    //console.log(ctxTimeline)
    

    const handlerClose = () => {
        if (callbackCancel) {
            callbackCancel()
        }
    }
    const handleOnConfirm = () => {
        if (callbackConfirm) {
            callbackConfirm()
        }
    }
    useEffect(() => {
        setIsOpen(status)
    }, [status])

    const handlerDialogNoTecnicas = () => {
        setIsPopUpNoTecnicas(true)
    }
    const handlerDialogStop = () => {
        setIsPopUpNoTecnicas(false)
        setIsPopUpCalidadRcp(false)

    }
    const handlerDialogCalidadRcp = () => {
        setIsPopUpCalidadRcp(true)
    }	
    
    const reportExist = async () => {

		const url = `${BASEURLAPI}/caseExist` //la url para la solicitud
		let result = false
		await axios.get(url)
			.then((response) => {
				if (response.status == 200) {
					if (response.data.result == true) {
						result = true
					}
				}
			})
			.catch((error) => {
				console.log('hubo un error', error)
			})

		return result
	}

    const SaveReport = async () => {
		const exist = await reportExist()
		if (!exist) {
			setLoadingText('Espere mientras el informe es guardado...')
			setIsLoading(true)
			const url = `${BASEURLAPI}/createCase`
			const formData = new FormData()
			formData.append('userId', userId)
			formData.append('name', `TESTCASE-${+new Date()} (Avanzado)`)
			formData.append('timeline', JSON.stringify(ctxTimeline))
			formData.append('duration', 0)
			axios.post(url, formData)
				.then((response) => {
					if (response.status == 200) {
						console.log(response.data)

					} else {

					}
					ctxReset.resetTimeline()
					setIsLoading(false)
				})
				.catch((error) => {
					console.log('hubo un error', error)
					setIsLoading(false)
				})
		}
	}
 
	const handleSaveAndCloseNotecnicas = () => {
		setIsPopUpNoTecnicas(false)
		SaveReport()
	}
	const   handleSaveAndCloseCalidadRcp = () => {
		setIsPopUpCalidadRcp(false)
        SaveReport()
	
	}

    
	const handleVolverNoTecnicas = () => {
		
        handlerClose()
	}
 
	const handleVolverCalidadRcp = () => {
		setIsPopUpCalidadRcp(false)
	}

    return (
        <>
        <Loader status={isLoading} text={loadingText} />
            {isOpen &&
                <div>
                    <DialogNoTecnicas
                        callbackConfirm={()=>{
                            setIsPopUpNoTecnicas(false)
                            setIsPopUpCalidadRcp(true)
                        }}
                        callbackCancel={() => handlerDialogStop()}
                        status={isPopUpNoTecnicas}
                        volverNoTecnicas={() => handleVolverNoTecnicas()} 
                        saveAnd={"Guardar y meedir calidad RCP"}
                    /> 
                    <DialogCalidadRcp
                        callbackConfirm={()=>{
                            setIsPopUpCalidadRcp(false)
                            handleOnConfirm()
                        }}
                        callbackCancel={()=>{
                            setIsPopUpNoTecnicas(true)
                            setIsPopUpCalidadRcp(false)
                        }}
                        status={isPopUpCalidadRcp}
                        volverCalidadRcp={() => handleVolverCalidadRcp()} 
                        saveAnd={"Guardar y generar informe"}
                    /> 

                </div>
            }
        </>);
}

export default DialogMedirAmbas;