
import React, { useEffect, useState, useContext } from "react"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons"; 
import NSMultiSelector from "../NSMultiSelector/NSMultiSelector";
import { isIPad13, isMobile } from "react-device-detect";
import { TimelineContext } from "../../helpers/Context";    
import { OptionsTecnicasFarmacos } from "../../helpers/Constants";
 import { iconTecnicas } from "../../helpers/ImagesCache";
 

const DialogTecnicasFarmacos = ({ contexto, callbackConfirm, callbackCancel}) => {  
     
    const [ tecnicas, setTecnicas] = useState( contexto.Tecnicas )  
    const [ farmacos, setFarmacos ] = useState( contexto.Farmacos )  

    const handlerClose = () => { 
        if(callbackCancel){ 
            callbackCancel()
        }
    }
 
    const handlerSelect = () => {   
        if ( callbackConfirm ) { 
            callbackConfirm( tecnicas, farmacos )  
        }
    }
   
    useEffect(()=>{
       console.log('se monto el componente DialogTecnicasFarmacos')
    },[])
    
    return (<>
        {
            
            <div className="flex overflow-hidden  flex-col items-center justify-end absolute left-0 top-0 bg-[#0009] w-full h-[100vh]" style={{zIndex:10001}}> 
                    <div   className='flex flex-col w-full h-[60px] pb-[10px] pt-[20px] px-[30px] items-between justify-center bg-white overflow-hidden '>  
                        <FontAwesomeIcon 
                            className="absolute right-[30px] cursor-pointer hover:text-red-500 hover:font-[bold]" 
                            onClick={handlerClose} 
                            icon={faClose}
                         />
                        <div className="flex items-center gap-3 ">
                            <button> 
                                <img src={iconTecnicas} alt="ritmo ecg" width={40} height={40}/> 
                            </button>
                            <span className=" text-2xl font-bold text-[#000e] ">
                                { 'Técnicas y Fármacos' }
                            </span> 
                        </div>
                    </div> 
                    
                    <div 
                        style={{minHeight:'50vh'}} 
                        className=" w-full px-[15px]  pb-[15px] max-h-[70vh] flex items-start bg-white overflow-auto  "
                    >
                        <div className="text-sm w-[50%] flex p-2  flex-col w-full h-full items-start justify-start overflow-auto "> 
                            <span style={{ fontSize:isMobile ? '18px':'22px' }} className="font-bold">
                                {"Fármacos"}
                            </span>
                            <NSMultiSelector 
                                options={OptionsTecnicasFarmacos[0]}  
                                onCallBack={(opt) => setTecnicas(opt) }
                                initial={contexto.Tecnicas}
                            /> 
                        </div>
                        <div className="text-sm w-[50%] flex p-2  flex-col w-full h-full items-start justify-start overflow-auto">
                            <span style={{ fontSize:isMobile?'18px':'22px' }} className="font-bold">
                                {"Fármacos"}
                            </span>
                            <NSMultiSelector 
                                options={OptionsTecnicasFarmacos[1]}  
                                onCallBack={(opt) => setFarmacos(opt) }
                                initial={contexto.Farmacos} 
                            /> 
                        </div>
                    </div>
                    <div style={styles.saveContainer}  >  
                        <button 
                            style={styles.saveButton}
                            onClick={ handlerSelect }
                            className="my-2 py-2 px-4 text-xl rounded">
                            Guardar y volver
                        </button> 
                    </div> 
            </div>
        }
        
    </>)
}

export default DialogTecnicasFarmacos

const styles = {
    saveContainer :{
        height: !isIPad13 ? '80px' : '80px',
        marginBottom: !isIPad13 ? '60px' : '30px',
        display:'flex',
        justifyContent:'center',
        alignItems: 'center',
        flexDirection: 'column',
        background:'white',
        overflow:'hidden',
        width:'100%' 
    },
    saveButton:{
        background:'#2aac9cff', 
        color:'white',
        fontWeight: 'bold' 
    }
}