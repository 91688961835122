import React,{useEffect, useState} from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { PRIMARY_COLOR } from "../../../helpers/Constants"

const Toogle = ({
    titles: titlesProps,
    initialState,
    isActive,
    onClick
}) =>{ 
    const [titles, setTitles] = useState(titlesProps  || [])  
    const [current, setCurrent] = useState(initialState) 
    const [active, setActive] = useState(isActive || true) 

    const handlerToogle = (item) => {
        if(!active) return
        setCurrent(item)
        if(onClick){ 
            onClick({
                active: item,
                title: titles[item]
            })
        } 
    }

    useEffect(()=>{
        

    },[current])
    
    return (<>
        <div style={{opacity:active?'100%':'50%'}} className="flex  items-center h-[40px] cursor-pointer ">
            <div className="flex p-1 items-center gap-2 bg-white rounded-[10px]">   
                {
                    titles.map((item, index)=>{  
                    return (
                        <div 
                        onClick={()=> handlerToogle(index)}
                        style={{backgroundColor:current == index ? PRIMARY_COLOR : 'white',transition:'ease-in',color: current == index? 'white' : 'black'}} 
                        className={`flex justify-center rounded-[10px] bg-[#fff] text-sm  px-2 py-1 w-[auto] font-[600] `}>
                            {item}
                        </div>
                        )
                    }) 
                } 
            </div>
        </div>
    </>) 
}

export default Toogle