/**
* 
* @author Juan Rico
* @module SoporteVitalBasico 
* @description  Este módulo define el la pagina simulacion basica.
* permite realizar la simulacion de soporte vital basico + DEA
* 
*/

//#region  Importaciones necesarias para el la pagina de Soporte vital básico + DEA. 
import React, { useState, useEffect, useContext } from 'react' // Importa React y hooks.
import './basic.css' // importacion de la hoja de estilos para esta pagina
import { isMobile, isDesktop, isTablet } from 'react-device-detect' // functiones para detectar tipos de dispositivos
import Header from '../../components/Header/Header' // Componente de la cabezera de la pagina.
import NSSwitcher from '../../components/NSButton/Types/Switcher' // Componente para botones de tipo switch
import NSBoolean from '../../components/NSButton/Types/Boolean' // Componente para botones de tipo Boleano
import NSTimer from '../../components/NSButton/Types/Timer' // Componente para botones de tipo Tiempo
import NSCounter from '../../components/NSButton/Types/Counter' // Componente para botones de tipo Contadores
import NSIndicator from '../../components/NSButton/Types/Indicator' // Componente para botones de tipo indicador
import NSSimpleButton from '../../components/NSButton/Types/SimpleButton'  // Componente para botones de tipo simple
import { faStopwatch } from '@fortawesome/free-solid-svg-icons' //importa iconos de fontawesome
import DialogVentilaciones from '../../components/DialogVentilaciones/DialogVentilaciones' // Componente para Pop-up de las ventilaciones
import DialogDownNotes from '../../components/DialogDownNotes/DialogDownNotes' // Componente para Pop-up de las notas
import DialogFinish from '../../components/DialogFinish/DialogFinish' // Componente para Pop-up de finalizacion
import {
	iconAvisaEquipo, //icono para el boton AVISAR EQUIPO DE EMERGENCIA
	iconCompresiones, //icono para el boton COMPRESIONES
	iconNoResponde, //icono para el boton NO RESPONDE
	iconNotas, //icono para el boton NOTAS
	iconVentilaciones, //icono para el boton VENTILACIONES
	iconReanimacion, //icono para el boton RCP
    iconDea,    
    iconPulmones,
    iconViaAerea,
    logoBasico,

} from '../../helpers/ImagesCache' //Iconos especificos cargados en cache por el desarrolador
import { ContextoReset } from '../../helpers/Context'  // Contextos de linea de tiempo y global.
import axios from 'axios';  // Librería para realizar solicitudes HTTP.
import { BASEURLAPI, ComponentOptions, TimelineDefault, DEVMODE } from '../../helpers/Constants'; //importa constantes 
import DialogHabilidades from '../../components/DialogHabilidades/DialogHabilidades' // Componente para Pop-up de hailidades luego de finalizar el caso
import DialogNoTecnicas from '../../components/DialogNoTecnicas/DialogNoTecnicas' // Componente para Pop-up de habilidades no tecnicas
import DialogCalidadRcp from '../../components/DialogCalidadRcp/DialogCalidadRcp' // Componente para Pop-up de calidad RCP
import DialogMedirAmbas from '../../components/DialogMedirAmbas/DialogMedirAmbas' // Componente para Pop-up de medir ambas
import Loader from '../../components/Loader/Loader' //importa componente loader para procesos de espera

//#endregion

const BasicSupport = () => {
	
//#region ############## CONSTANTES LOCALES ESTATICAS ##############// 
	/**
     * Vaor por defecto para los handles de temporizador
     * @type {Object}
     */
	const defaultTimerHandleValue = { startAt: +new Date(), stopAt: +new Date(),ritmo:[] }
//#endregion

//#region ############## USO DEL CONTEXTO GLOBAL ##############//
	/**
     * Contexto global
     * @type {Object}
     */
	const ctxReset = useContext(ContextoReset);
//#endregion

//#region ############## VARIABLES DE ESTADO PARA CONTEXTO LOCAL ##############//
	/**
     * Variable de estado para la linea de tiempo incia constante
     * @type {Object}
     */
	const [ctxTimeline, setCtxTimeLine] = useState(TimelineDefault);

	/**
     * Variable de estado para el historial 
     * @type {Array}
	 * @default [] 
     */
	const [History, setHistory] = useState([]); 
//#endregion

//#region ############## VARIABLES DE ESTADO GENERALES ##############//
	/**
     * Variable de estado para controlar el inicio del caso
     * @type {Boolean}
	 * @default false
     */
	const [IsStarted, setIsStarted] = useState(false)

	/**
     * Variable de estado para forzar la detencio del caso
     * @type {Boolean}
	 * @default false
     */
	const [ForceStopCase, setForceStopCase] = useState(false)

	/**
     * Variable de estado para intentar detener el caso - falta explicarla bien
     * @type {Boolean}
	 * @default false
     */
	const [IsTryingToStop, setTsTryingToStop] = useState(false)

	/**
     * Variable de estado para mostrar el popup de compresiones
     * @type {Boolean}
	 * @default true
     */
	const [IsPopupComp, setIsPopupComp] = useState(true)
 
	/**
     * Variable de estado para mostrar el popup de evaluar ritmo 
     * @type {Boolean}
	 * @default false
     */
	const [IsPopupRitmo, setIsPopupRitmo] = useState(false) 
 
	/**
     * Variable de estado para mostrar el popup de notas
     * @type {Boolean}
	 * @default false
     */
	const [IsPopupNotas, setIsPopupNotas] = useState(false)

	/**
     * Variable de estado para mostrar el popup de habilidades tecnicas
     * @type {Boolean}
	 * @default false
     */
	const [IsPopupHabilidades, setIsPopupHabilidades] = useState(true)

	/**
     * Variable de estado para mostrar el popup de habilidades no tecnicas
     * @type {Boolean}
	 * @default false
     */
	const [IsPopUpNoTecnicas, setIsPopUpNoTecnicas] = useState(false)

	/**
     * Variable de estado para mostrar el popup de calidad rcp
     * @type {Boolean}
	 * @default false
     */
	const [IsPopUpCalidadRcp, setIsPopUpCalidadRcp] = useState(false)

	/**
     * Variable de estado para mostrar el popup de medir ambas
     * @type {Boolean}
	 * @default false
     */
	const [IsPopUpMedirAmbas, setIsPopUpMedirAmbas] = useState(false)

	/**
     * Variable de estado para mostrar el popup de finalizar reporte para guardarlo
     * @type {Boolean}
	 * @default false
     */
	const [IsPopUpFinish, setIsPopUpFinish] = useState(false)

	/**
     * Variable de estado para determinar si se mediran o no las compresiones
     * @type {Boolean}
	 * @default false
     */
	const [compresions, setCompresions] = useState(false) 
 
	 /**
     * Variable de estado para guardar el id del usuario actual
     * @type {Number}
	 * @default 2
     */
	const [userId, setUserId] = useState(2)

	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {boolean}
	 * @default false - al iniciar esta oculto
	 */
	const [isLoading, setIsLoading] = useState(false)

	/**
	 * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
	 * @type {string}
	 * @default 'Guardando informe ...' - al iniciar tiene el texto 'Guardando informe...'
	 */
	const [loadingText, setLoadingText] = useState('Guardando informe ...')
//#endregion

//#region ############## FUNCIONES HANDLE BASICAS ##############//
	/**
	 * Manejador del estado forceStopCase 
	 * @function handleForceStopCase 
	 * @param boolean - value
	 */
	const handleForceStopCase = (value) => setForceStopCase(value)

	/**
	 * Manejador del estado IsStarted para activarlo
	 * @function handleStart  
	 */
	const handleStart = () => setIsStarted(true)

	/**
	 * Manejador del estado IsStarted para deshabilitar el caso
	 * @function handleStop  
	 */
	const handleStop = () => { setIsStarted(false) }

	/**
	 * Manejador del estado IsTryingToStop para cambiar su estado en modo toggle
	 * @function handleStopDialog  
	 */
	const handleStopDialog = () => { setTsTryingToStop(!IsTryingToStop) }

	/**
	 * Manejador del estado isPopUpActive para desactivarlo
	 * @function handleCloseDialog  
	 */
	const handleCloseDialog = () => setIsPopupComp(false) 

	/**
	 * Manejador del estado IsPopupNotas para cambiar su estado en modo toggle
	 * @function handleNotasDialog  
	 */
	const handleNotasDialog = () => setIsPopupNotas(!IsPopupNotas)

	/**
	 * Manejador del estado IsPopUpNoTecnicas para cambiar su estado a true
	 * @function handleDialogNoTecnicas  
	 */
	const handleDialogNoTecnicas = () => { setIsPopUpNoTecnicas(true) } 

	/**
	 * Manejador del estado IsPopUpCalidadRcp para cambiar su estado a true
	 * @function handleDialogCalidadRcp  
	 */
	const handleDialogCalidadRcp = () => { setIsPopUpCalidadRcp(true) } 

	/**
	 * Manejador del estado IsPopUpMedirAmbas para cambiar su estado a true
	 * @function handleDialogMedirAmbas  
	 */
	const handleDialogMedirAmbas = () => {  
		setIsPopUpMedirAmbas(true) 
	}  
 

	/**
	 * Manejador del estado IsPopUpFinish para cambiar su estado en modo toggle
	 * @function handleDialogFinish  
	 */
	const handleDialogFinish  = () => { 
		setIsPopUpFinish(true) 
		// TEST setIsPopUpFinish(!IsPopUpFinish) 
	} 

	/**
	 * Manejador del estado IsPopUpFinish para cambiar su estado a false
	 * @function handleDialogFinishStop  
	 */
	const handleDialogFinishStop  = () => { setIsPopUpFinish(false) }

	/**
	 * Manejador del estado IsPopUpNoTecnicas para cambiar su estado a false
	 * @function handleVolverNoTecnicas  
	 */
	const handleVolverNoTecnicas = () => { setIsPopUpNoTecnicas(false) } 

	/**
	 * Manejador del estado isPopUpCalidadRco para cambiar su estado a false
	 * @function handleVolverCalidadRcp  
	 */
	const handleVolverCalidadRcp = () => { setIsPopUpCalidadRcp(false) }

	/**
	 * Manejador del estado para cerrar las habilidades no tecnicas 
	 * @function handleDialogNoTecnicasStop  
	 */
	const handleDialogNoTecnicasStop = () => {
		setIsPopUpNoTecnicas(false)
		setIsPopupHabilidades(false)
	}
 
	/**
	 * Manejador del estado para cerrar la calidad rcp
	 * @function handleDialogCalidadRcpStop  
	 */
	const handleDialogCalidadRcpStop = () => {
		setIsPopUpCalidadRcp(false)
		setIsPopupHabilidades(false)
	}

	/**
	 * Manejador del estado para cerrar medir ambas 
	 * @function handleMedirAmbasStop  
	 */
	const handleMedirAmbasStop = () => {
		setIsPopUpMedirAmbas(false)
		setIsPopupHabilidades(false)
	}

	/**
	 * Manejador del estado  para setear la medicion de compresiones activas
	 * @function handleCompresions  
	 */
	const handleCompresions = () => {
		setIsPopupComp(false)
		setCompresions(true)
	}

	/**
	 * Manejador del estado para finalizar las habilidades no tecnicas 
	 * y pasar al resumen del reporte
	 * @function handleSaveAndCloseNotecnicas  
	 */
	const handleSaveAndCloseNotecnicas = () => {
		setIsPopUpNoTecnicas(false)
		setIsPopupHabilidades(false)
		handleDialogFinish()
		//SaveReport()
	}

	/**
	 * Manejador del estado para finalizar las habilidades no tecnicas 
	 * y pasar al resumen del reporte
	 * @function handleSaveAndCloseNotecnicas  
	 */
	const handleDialogMedirAmbasConfirm = () => {
		setIsPopUpNoTecnicas(false)
		setIsPopupHabilidades(false)
		setIsPopUpMedirAmbas(false)
		handleDialogFinish()
		//SaveReport()
	}


	/**
	 * Manejador del estado para finalizar la calidad rcp 
	 * y pasar al resumen del reporte
	 * @function handleSaveAndCloseCalidadRcp  
	 */
	const handleSaveAndCloseCalidadRcp = () => {
		setIsPopUpCalidadRcp(false)
		setIsPopupHabilidades(false)
		handleDialogFinish() 
	}
//#endregion

//#region ############## FUNCIONES HANDLE AVANZADOS DE COMPONENTES ##############
	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton INICIAR CASO
	 * 
	 * @function handleStartTimerCase 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerCase = (_value = defaultTimerHandleValue) => {
		updateContext( 'startCase', _value.startAt ) 
		updateHistory( 'startCase', _value.startAt, 'Inicio de Caso' ) 
		handleStart()
	}

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton DETENER CASO
	 * 
	 * @function handleStopTimerCase 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerCase = (_value = defaultTimerHandleValue) => {
		handleForceStopCase(true) 
		updateContext( 'startCase', _value.startAt ) 
		updateContext( 'stopCase', _value.stopAt )  
		updateHistory( 'stopCase', _value.stopAt, 'Fin del Caso' )  
		handleStop()
		handleStopDialog()
	}

	/**
	 * Handle utilizado iniciar la parada cardio respiratoria
	 * tiene un cronometro en el boton INICIO PCR
	 * 
	 * @function handleStartTimerPCR 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerPCR = (_value = defaultTimerHandleValue) => {
		updateContextPush('PCR', { 
			start: _value.startAt 
		})
		updateHistory('PCR', _value.startAt, 'Inicio PCR')
	}

	/**
	 * Handle utilizado para detener la parada cardio respiratoria 
	 * detiene el cronometro para entrar en modo RCE
	 * 
	 * @function handleStopTimerPCR 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerPCR = (_value = defaultTimerHandleValue ) => { 
		updateContextPush('RCE',{ 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		updateHistory('RCE', _value.stopAt, 'RCE')  
	}

	/**
	 * Handle utilizado para gestionar la accion del switch Escenario seguro
	 * 
	 * @function handleIsSafeSceneSwitch 
	 * @param _value object - nulleable 
	 * @default null
	 */
	const handleIsSafeSceneSwitch = (value = null) => {
		updateContext('isSafeScene', value) 
		updateHistory(
			'isSafeScene', 
			+new Date(0),
			'Escenario seguro'
		) 
	}

	/**
	 * Handle utilizado para gestionar la accion del switch Grita Ayuda 
	 * 
	 * @function handleShouteHelpSwitch 
	 * @param _value object - nulleable 
	 * @default null
	 */
	const handleShouteHelpSwitch = (value = null) => { 
		updateContext('shoutedHelp', value)
		updateHistory(
			'shoutedHelp', 
			+new Date(),
			'Grita Ayuda'
		)  
	}

	
	/**
	 * Handle utilizado para gestionar la accion del switch Canula de Guedel 
	 * 
	 * @function handleGuedelCannula 
	 * @param _value object - nulleable 
	 * @default null
	 */
	const handleGuedelCannula = (value = null) => { 
		updateContext('guedelCannula', value)
		updateHistory(
			'guedelCannula', 
			+new Date(),
			'Canula de Guedel'
		)  
	} 
	/**
	 * Handle utilizado para gestionar ç
	 * la accion del boton NO RESPONDE 
	 * 
	 * @function hanldeNotResposeButton 
	 * @param _value object - nulleable 
	 * @default null
	 */
	const hanldeNotResposeButton = (value = null) => {
		if (value) { 
			updateContext('notRespond', +new Date() ) 
		}
		updateHistory(
			'notRespond', 
			+new Date(),
			'No responde / No respira'
		)  
	}
	/**
	 * Handle utilizado para gestionar ç
	 * la accion del boton NO RESPONDE 
	 * 
	 * @function hanldeLateralSafetyPositionButton 
	 * @param _value object - nulleable 
	 * @default null
	 */
	const hanldeLateralSafetyPositionButton = (value = null) => {
		if (value) { 
			updateContext('lateralSafetyPosition', +new Date() ) 
		}
		updateHistory(
			'lateralSafetyPosition', 
			+new Date(),
			'Posición lateral de seguridad'
		)  
	}
 

	/**
	 * Handle utilizado para gestionar ç
	 * la accion del boton NO RESPONDE 
	 * 
	 * @function hanldeNotifiesEmergencyTeamButton 
	 * @param _value object - nulleable 
	 * @default null
	 */
	const hanldeNotifiesEmergencyTeamButton = (value = null) => {
		if (value) { 
			updateContext('notifiesEmergencyTeam', +new Date() ) 
		}
		updateHistory(
			'notifiesEmergencyTeam', 
			+new Date(),
			'Avisa equipo de emergencias'
		)  
	}

	
	 
	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton COMPRESIONES
	 * 
	 * @function handleOpenAirWayStartCounter 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleOpenAirWayStartCounter = (_value = defaultTimerHandleValue) => {
		updateHistory(
			'openAirWay', //key
			_value.startAt, //tiempo
			'Abre via respiratoria' // titulo
		) 
	} 
	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton COMPRESIONES
	 * 
	 * @function handleOpenAirWayStopCounter 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleOpenAirWayStopCounter = (_value = defaultTimerHandleValue) => {
		updateContextPush('openAirWay', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		
		updateHistory(
			'openAirWay', //key
			_value.stopAt, //tiempo
			'Abre via respiratoria' // titulo
		)  
	}


/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton COMPRESIONES
	 * 
	 * @function handleCheckVentilationStartCounter 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleCheckVentilationStartCounter = (_value = defaultTimerHandleValue) => {
		updateHistory(
			'checkVentilation', //key
			_value.startAt, //tiempo
			'Comprueba ventilación' // titulo
		) 
	} 

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton COMPRESIONES
	 * 
	 * @function handleCheckVentilationStopCounter 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleCheckVentilationStopCounter = (_value = defaultTimerHandleValue) => {
		updateContextPush('checkVentilation', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		
		updateHistory(
			'checkVentilation', //key
			_value.stopAt, //tiempo
			'Comprueba ventilación' // titulo
		)  
	}
 

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton COMPRESIONES
	 * 
	 * @function handleStopTimerCompressions 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerCompressions = (_value = defaultTimerHandleValue) => {
		 
		updateHistory(
			'compressions', //key
			_value.stopAt, //tiempo
			'Detiene Compresiones' // titulo
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton COMPRESIONES
	 * 
	 * @function handleStartTimerCompression 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerCompression = (_value = defaultTimerHandleValue) => {
		updateHistory(
			'compressions', //key
			_value.startAt, //tiempo
			'Inicia Compresiones' // titulo
		) 
	}

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton VENTILACIONES
	 * 
	 * @function handleStopTimerVents 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerVents = (_value = defaultTimerHandleValue) => {
		updateContextPush('vents', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		}) 
		updateHistory(
			'vents', //key
			_value.stopAt, //tiempo
			'Detiene ventilaciones' // titulo
		) 
	}

	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton VENTILACIONES
	 * 
	 * @function defaultTimerHandleValue 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerVents = (_value = defaultTimerHandleValue) => {
		updateHistory(
			'vents', //key
			_value.startAt, //tiempo
			'inicia ventilaciones' // titulo
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton RCP
	 * 
	 * @function handleStopTimerRcp 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStopTimerRcp = (_value = defaultTimerHandleValue) => { 
		updateContextPush('RCP', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		updateHistory(
			'RCP', //key
			_value.stopAt, //tiempo
			'Detiene RCP' // titulo
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton RCP
	 * 
	 * @function handleStartTimerRcp 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerRcp = (_value = defaultTimerHandleValue) => {
		updateHistory(
			'RCP', //key
			_value.startAt, //tiempo
			'inicia RCP' // titulo
		)  
	}

	/**
	 * Handle utilizado para gestionar la accion de detener 
	 * del cronometro del boton DEFIFRILACION
	 * 
	 * @function hanldeStopTimerDefibrillation 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const hanldeStopTimerDefibrillation = (_value = defaultTimerHandleValue) => { 
		updateContextPush('DEA', { 
			start: _value.startAt, 
			stop: _value.stopAt 
		})
		updateHistory(
			'DEA', //key
			_value.stopAt, //tiempo
			'DESCARGA', // titulo,
			false, // ocultar
			'', // titulo secundario
			'#F4D947' // color primario 
		) 
			
	}

	/**
	 * Handle utilizado para gestionar la accion del inicio 
	 * del cronometro del boton DEFIFRILACION
	 * 
	 * @function handleStartTimerDefibrillation 
	 * @param _value object - nulleable - defaultTimerHandleValue 
	 * @default defaultTimerHandleValue - el valor puede traer los valores startAt,stopAt o ritmo
	 */
	const handleStartTimerDefibrillation = (_value = defaultTimerHandleValue) => {
		updateHistory(
			'DEA', //key
			_value.startAt, //tiempo
			'Coloca DEA', // titulo
			true, // ocultar en reporte 
		) 
			
	} 

	/**
	 * Handle utilizado para gestionar
	 * la accion CLICK de confirmacion para 
	 * guardar las notas
	 * 
	 * @function handleDialogConfirmNotes  
		 */
	const handleDialogConfirmNotes = (text = '')=> {
		updateContext('Notas', text)
		if(text != ctxTimeline.Notas){ 
			updateHistory(
				'Notas', //key
				+new Date(), //tiempo
				'Notas', // titulo,
				false, // ocultar
				text, // titulo secundario
				'#2AAC9C' // color primario 
			)  
		}
		handleNotasDialog()
	}



//#endregion
		
//#region ############## FUNCIONES AVANZADAS ##############//
	/**
	 * Verifica si el informe ya se ha guardado en la base de datos.
	 * @async
	 * @function reportExist
	 * @todo
	 * api que verifique la existencia de un reporte
	 *  
	 */
	const reportExist = async () => {

		const url = `${BASEURLAPI}/caseExist` //la url para la solicitud
		let result = false
		await axios.get(url)
			.then((response) => {
				if (response.status == 200) {
					if (response.data.result == true) {
						result = true
					}
				}
			})
			.catch((error) => {
				console.log('hubo un error', error)
			})

		return result
	}

	/**
	 * Guarda el ingorme en la base de datos una vez finalizado el caso.
	 * 
	 * @function SaveReport
	 * @todo
	 * verificar si el informe ya existe
	 */
	const SaveReport = async () => {
		const exist = await reportExist()
		if (!exist) { 
			setLoadingText('Espere mientras el informe es guardado...')
			setIsLoading(true)
			const url = `${BASEURLAPI}/createCase`
			const formData = new FormData()
			formData.append('userId', userId)
			formData.append('name', `${ctxTimeline.name} (Basico)`)
			formData.append('timeline', JSON.stringify(ctxTimeline))
			formData.append('duration', 0)
			axios.post(url, formData)
				.then((response) => {
					if (response.status == 200) {
						if( DEVMODE ) console.log(response.data)
						//aqui deberia ser redirigido hacia reportes
					} else {

					}
					ctxReset.resetTimeline()
					setIsLoading(false)
					setIsPopUpFinish(false)
				})
				.catch((error) => {
					console.log('hubo un error', error)
					setIsLoading(false)
				})
		}
	}

	/**
	 * Actualizar el contexto de forma mas simple pasandole el key y el valor
	 * 
	 * @function updateContext 
	 * @param key string - mandatory
	 * @param value any - nulleable
	 */
	const updateContext = ( key ,  value = null ) => { 
		if( DEVMODE ){ 
			console.log(
				'%cACTUALIZANDO CONTEXTO, Agregando el objeto ' + key + ' con valor: ',
				'color:#5f5',
				value
			)
		} 
		setCtxTimeLine(
			(prev)=>( 
				{
					...prev, 
					[key]: value 
				} 
			)
		)  
	}

	/**
	 * Actualizar un elemento del contexto de forma mas simple pasandole el valor
	 * 
	 * @function updateContextPush  
	* @param key string - mandatory
	 * @param value any - nulleable
	 */
	const updateContextPush = (key , value = null ) => { 
		if( DEVMODE ){ 
			console.log(
				'%cAGREGANDO AL CONTEXTO PUSH, Agregando el objeto ' + key + ' con valor: ' + value,
				'color:orange'
			)
		} 
		setCtxTimeLine(
			(prev) => (
				{ 	
					...prev, 
					[key]: [ ...prev[key], value] 
				}
			)
		)  
	}

	/**
	 * Actualizar el historial de forma mas simple pasandole parametros especificos
	 * 
	 * @function updateHistory 
	 * @param _object string - nulleable - nombre del key en el contextTimeline de 1er nivel
	 * @param _time Date - nulleable - datetime  
	 * @param _title string - nulleable - titulo que aparece en el reporte
	 * @param _hideOnReport boolean - nulleable - si se mostrara o no en el reporte
	 * @param _value string - nulleable - valor secundario en el reporte
	 * @param _colorPrimario string - nulleable - color del texto secundario
	 * @param _colorSecundario string - nulleable - color secundario del texto secundario
	 */
	const updateHistory = (
		_object = '', 
		_time = +new Date(), 
		_title = '-', 
		_hideOnReport = false,
		_value = null, 
		_colorPrimario = null ,
		_colorSecundario = null 
	) => {  
		if( DEVMODE ){ 
			console.log(
				'%cACTUALIZANDO HISTORIAL, Agregando el objeto ' + _object,
				'color:yellow'
			)
		}
		setHistory(
			(prev)=>(
				[
					...prev, 
					{
						object: _object,
						time: _time,
						title: _title,
						value: _value,
						colorPrimario: _colorPrimario,
						hideOnReport: _hideOnReport,
						colorSecundario: _colorSecundario
					} 
				]
			)
		)
	}
//#endregion

//#region ############## USEEFFECTS ##############//
	   
	// Hook que se ejecuta al montar el componente para verificar el contexto el tipo de caso advance .
	useEffect(() => { updateContext( 'type', 'basic' ) }, []) 

	// Hook que se ejecuta al montar el componente y actualiza el historial en el cotexto
	useEffect(() => {  updateContext( 'history', History ) }, [History])
//#endregion

	return (
		<>
			<Loader status={isLoading} text={loadingText} />
			<div style={styles.main} >
				<Header options={{}} minimal={true} logo={logoBasico} />
				{
//#region  TEMPORIZADORES GENERALES
				}
				<div className='flex gap-8 my-3 lg:my-2'>
					<NSTimer
						titleOn 	 = 'Parar caso' //Titulo cuando su estado es activo
						titleOff 	 = 'Iniciar caso' //Titulo cuando su estado esta inactivo - por defecto
						onStartTimer = { handleStartTimerCase } //Accion al INICIAR el temporizador
						onStopTimer  = { handleStopTimerCase } //Accion al DETENER el temporizador
						forceDisable = { ForceStopCase } //Forzar la desactivacion la accion de este componente
						forceStop 	 = { ForceStopCase } //Forzar DETENER el temporizador
					/>
					<NSTimer 
						titleOn 	 = 'RCE' //Titulo cuando su estado es activo
						titleOff 	 = 'Inicio PCR' //Titulo cuando su estado esta inactivo - por defecto
						onStartTimer = { handleStartTimerPCR } //Accion al INICIAR el temporizador
						onStopTimer  = { handleStopTimerPCR }  //Accion al DETENER el temporizador
						forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
						forceStop 	 = { ForceStopCase } //Forzar DETENER el temporizador
					/>
				</div>
				<div className='w-full ' style={styles.bodyContent} >
					<div className="p-2 w-full  h-full " style={styles.switcherSection}>
					{
//#endregion 
//#region  BOTONES SWITCH 
					}
						<div className="w-10/12 flex justify-between  ">
							<NSSwitcher
								title		 = 'Escenario Seguro'  //Titulo visible
								onClick		 = { handleIsSafeSceneSwitch }  //Accion cuando se hace CLICK
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
							/>
							<NSSwitcher
								title		 = 'Grita Ayuda'  //Titulo visible
								onClick		 = { handleShouteHelpSwitch } //Accion cuando se hace CLICK
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
							/>
							<NSSwitcher
								title		 = 'Canula de Guedel'  //Titulo visible
								onClick		 = { handleGuedelCannula } //Accion cuando se hace CLICK
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
							/>
						</div>
 
					{ 
//#endregion 
//#region  BOTONES VARIABLES DEL CONTENIDO 
					}

						<div className="h-full" style={styles.buttonsSeccion}  > 
							 
							<NSBoolean
								title		 = { 'Comprueba consciencia' }  //Titulo visible
								icon		 = { iconNoResponde } //Icono del componente
								initialState = { false } //Estado inicial del componente
								onClick		 = { hanldeNotResposeButton }  //Accion cuando se hace CLICK 
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador 
							/> 
							<NSCounter
								titleStart	 = { 'Abre via aerea' } //Titulo por defecto cuando inicializa
								titleOn		 = { 'Abre via aerea' }  //Titulo cuando su estado esta activo
								titleOff	 = { 'Abre via aerea' }  //Titulo cuando su estado esta INACTIVO
								icon		 = { iconViaAerea } //Icono del componente
								type		 = { 'restart' } //tipo de componente personalizado
								sum			 = { 1 } //valor a sumar en el inicio del componente
								onStopTimer	 = { handleOpenAirWayStopCounter } //Accion al DETENER el temporizador
								onStartTimer = { handleOpenAirWayStartCounter } //Accion al INICIAR el temporizador
								list		 = { ctxTimeline.openAirWay } //Lista de contexto para el conteo de elementos
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador 
								onlyCounter //Oculta el Temporizador y solo muestra el contador 
							/> 
							<NSCounter
								titleStart	 = { 'Comprueba ventilacion' } //Titulo por defecto cuando inicializa
								titleOn		 = { 'Comprueba ventilacion' }  //Titulo cuando su estado esta activo
								titleOff	 = { 'Comprueba ventilacion' }  //Titulo cuando su estado esta INACTIVO
								icon		 = { iconPulmones } //Icono del componente
								type		 = { 'restart' } //tipo de componente personalizado
								sum			 = { 1 } //valor a sumar en el inicio del componente
								onStopTimer	 = { handleCheckVentilationStopCounter } //Accion al DETENER el temporizador
								onStartTimer = { handleCheckVentilationStartCounter } //Accion al INICIAR el temporizador
								list		 = { ctxTimeline.checkVentilation } //Lista de contexto para el conteo de elementos
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador 
								onlyCounter //Oculta el Temporizador y solo muestra el contador 
							/> 
							<NSBoolean
								title		 = { 'Avisa equipo de emergencias' }  //Titulo visible
								icon		 = { iconAvisaEquipo } //Icono del componente
								initialState = { false } //Estado inicial del componente
								onClick		 = { hanldeNotifiesEmergencyTeamButton }  //Accion cuando se hace CLICK 
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador 
							/>
							
							{
								compresions &&
								<NSCounter
									titleStart	 = { 'Inicia compresiones' } //Titulo por defecto cuando inicializa
									titleOn		 = { 'Detiene compresiones' } //Titulo cuando su estado esta activo
									titleOff	 = { 'Inicia compresiones' } //Titulo cuando su estado esta INACTIVO
									icon		 = { iconCompresiones } //Icono del componente
									sum			 = { 1 } //valor a sumar en el inicio del componente
									onStopTimer	 = { handleStopTimerCompressions } //Accion al DETENER el temporizador
									onStartTimer = { handleStartTimerCompression } //Accion al INICIAR el temporizador
									list		 = { ctxTimeline.compressions } //Lista de contexto para el conteo de elementos
									forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
									forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
									onlyCounter //Oculta el Temporizador y solo muestra el contador
									initialState //Estado inicial del componente
								/>
							}
							{
								compresions &&
								<NSCounter
									titleStart	 = { 'Inicia ventilaciones' } //Titulo por defecto cuando inicializa
									titleOn	 	 = { 'Detiene ventilaciones' } //Titulo cuando su estado esta activo
									titleOff	 = { 'Inicia ventilaciones' } //Titulo cuando su estado esta INACTIVO
									icon		 = { iconVentilaciones } //Icono del componente
									sum			 = { 1 } //valor a sumar en el inicio del componente
									onStopTimer	 = { handleStopTimerVents } //Accion al DETENER el temporizador
									onStartTimer = { handleStartTimerVents } //Accion al INICIAR el temporizador
									list		 = { ctxTimeline.vents } //Lista de contexto para el conteo de elementos
									forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
									forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
									onlyCounter //Oculta el Temporizador y solo muestra el contador
								/>
							}
							{
								!compresions && 
								<NSCounter
									titleStart	 = { 'Inicia RCP' } //Titulo por defecto cuando inicializa
									titleOn		 = { 'Detiene RCP' } //Titulo cuando su estado esta activo
									titleOff	 = { 'Inicia RCP' } //Titulo cuando su estado esta INACTIVO
									icon		 = { iconReanimacion } //Icono del componente 
									sum			 = { 1 } //valor a sumar en el inicio del componente
									onStopTimer	 = { handleStopTimerRcp } //Accion al DETENER el temporizador
									onStartTimer = { handleStartTimerRcp } //Accion al INICIAR el temporizador
									list		 = { ctxTimeline.RCP } //Lista de contexto para el conteo de elementos
									forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
									forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
								/>
							}
							<NSCounter
								titleStart	 = { 'Coloca DEA' } //Titulo por defecto cuando inicializa
								titleOn	 	 = { 'DESCARGA' } //Titulo cuando su estado esta activo
								titleOff	 = { 'DESCARGA' } //Titulo cuando su estado esta INACTIVO
								icon		 = { iconDea } //Icono del componente
								type		 = { 'restart'  } //valor a sumar en el inicio del componente 
								sum			 = { 0 } //valor a sumar en el inicio del componente
								onStopTimer	 = { hanldeStopTimerDefibrillation } //Accion al DETENER el temporizador
								onStartTimer = { handleStartTimerDefibrillation } //Accion al INICIAR el temporizador
								list		 = { ctxTimeline.DEA } //Lista de contexto para el conteo de elementos
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
								disableInitialTimer 
								onlyCounter //Oculta el Temporizador y solo muestra el contador
							/>
							<NSBoolean
								title		 = { 'Posicion lateral de seguridad' }  //Titulo visible
								icon		 = { null } //Icono del componente
								initialState = { false } //Estado inicial del componente
								onClick		 = { hanldeLateralSafetyPositionButton }  //Accion cuando se hace CLICK 
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador 
							/> 
							<NSIndicator
								title		 = { 'Notas' } //Titulo por defecto cuando inicializa
								icon		 = { iconNotas } //Icono del componente 
								values		 = { [{ status: ctxTimeline.Notas == '' ? false : true } ] } //Valores personalizados del componente
								options		 = { ComponentOptions.notesButton } //Opciones personalizadas del componente 
								onClick		 = { () => { handleNotasDialog() } }  //Accion cuando se hace CLICK
								forceDisable = { !IsStarted } //Forzar la desactivacion la accion de este componente
								forceStop	 = { ForceStopCase } //Forzar DETENER el temporizador
								initialState //Estado inicial del componente
								minimal //Activar la version minimalista
							/>
						</div>
					</div>
				</div>
				{
//#endregion 
//#region  FOOTER
				} 
				<div className='w-full ' style={styles.footer} >
					<NSSimpleButton
						title		 = { 'Informe' } //Titulo por defecto cuando inicializa
						initialState = { ForceStopCase } //Estado inicial del componente
						callBack 	 = { ()=>handleDialogFinish() } //Accion de respuesta al boton
					/>
				</div>
			{
//#endregion
			}
			</div>
			{
//#region DIALOGOS POPUP
			}
			<DialogVentilaciones
				text			= { 'Desea medir compresiones y ventilaciones?' } //Titulo por defecto cuando inicializa
				icon			= { faStopwatch } //Icono del componente
				callbackConfirm	= { handleCompresions } //Accion cuando se presiona CONFIRMAR
				callbackCancel	= { handleCloseDialog } //Accion cuando se presiona CANCELAR
				status			= { IsPopupComp } //Estado del compononte (Visible || oculto)
			/> 
			   
			{
				IsPopupNotas &&
				<DialogDownNotes
					text			= { 'Notas' } //Titulo por defecto cuando inicializa
					icon			= { iconNotas } //Icono del componente
					callbackConfirm = { handleDialogConfirmNotes } //Accion cuando se presiona CONFIRMAR
					callbackCancel  = { handleNotasDialog } //Accion cuando se presiona CANCELAR
					status			= { IsPopupNotas } //Estado del compononte (Visible || oculto)
					contexto   		= { ctxTimeline }
				/>
			} 
			<DialogHabilidades
				text			= { '¿Desea medir las habilidades no técnicas y/o la calidad de las RCP?' } //Titulo por defecto cuando inicializa
				callbackConfirm	= { handleStop } //Accion cuando se presiona CONFIRMAR
				callbackCancel	= { () => setIsPopupHabilidades(false) } //Accion cuando se presiona CANCELAR
				status			= { ForceStopCase && IsPopupHabilidades } //Estado del compononte (Visible || oculto)
				action01		= { () => handleDialogNoTecnicas() } //Accion al presionar el PRIMER boton
				action02		= { () => handleDialogCalidadRcp() } //Accion al presionar el SEGUNDO boton
				action03		= { () => handleDialogMedirAmbas() } //Accion al presionar el TERCER boton
				action04		= { 
					() => {
						handleDialogFinish(); //Abre el PopUp para finalizar el registro
						setIsPopupHabilidades(false); // cierra el popup de habilidades
					}
				} //Accion al presionar el CUARTO boton
			/>
			<DialogNoTecnicas
				callbackConfirm  = { () => {} } //Accion cuando se presiona CONFIRMAR
				callbackCancel	 = { () => handleDialogNoTecnicasStop() } //Accion cuando se presiona CANCELAR
				status			 = { IsPopUpNoTecnicas } //Estado del compononte (Visible || oculto)
				volverNoTecnicas = { () => handleVolverNoTecnicas() } //Accion al presionar VOLVER en No Tecnicas
				actionNoTecnicas = { () => handleSaveAndCloseNotecnicas() } //Accion al presionar GUARDAR en No Tecnicas
				saveAnd			 = { "Guardar y generar informe" } //Titulo personalizado del boton guardar

			/>

			<DialogCalidadRcp
				callbackConfirm	 = { () => {} } //Accion cuando se presiona CONFIRMAR
				callbackCancel	 = { () => handleDialogCalidadRcpStop() } //Accion cuando se presiona CANCELAR
				status			 = { IsPopUpCalidadRcp } //Estado del compononte (Visible || oculto)
				volverCalidadRcp = { () => handleVolverCalidadRcp() }  //Accion al presionar VOLVER en calidad RCP
				actionCalidadRcp = { () => handleSaveAndCloseCalidadRcp() } //Accion al presionar GUARDAR en calidad RCP
				saveAnd			 = { "Guardar y generar informe" } //Titulo personalizado del boton guardar
			/> 
			<DialogMedirAmbas
				callbackConfirm = { handleDialogMedirAmbasConfirm } //Accion cuando se presiona CONFIRMAR
				callbackCancel 	= { handleMedirAmbasStop } //Accion cuando se presiona CANCELAR
				status			= { IsPopUpMedirAmbas } //Estado del compononte (Visible || oculto)
			/> 
			{
				IsPopUpFinish &&
				<DialogFinish 
					callbackConfirm = { () => SaveReport() } //Accion cuando se presiona CONFIRMAR
					callbackCancel  = { handleDialogFinishStop } //Accion cuando se presiona CANCELAR
					selectedReport  = { ctxTimeline } //Pasar por props el reporte seleccionado 
					onUpdateContext = { updateContext }
				/>
			}
			{
//#endregion
			}
		</>

	)
}
export default BasicSupport


//#region TABLA DE ESTILOS 
const styles = {
	main: {
		maxWidth: isTablet ? '100%' : isDesktop ? '1280px' : '700px',
		maxHeight: '100vh',
		position: 'fixed',
		left: 0,
		right: 0,
		top: 0,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 'auto',
		marginLeft: 'auto'

	},
	bodyContent: {
		display: 'flex',
		zIndex: 0,
		background: '#eaf7f5',
		overflowY: 'auto',
		height: '100%',
		minHeight: '70vh',
		maxHeight: '70vh'
	},
	switcherSection: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	buttonsSeccion: {
		gap: isMobile ? '2%' : '1%',
		justifyContent: 'center',
		marginBottom: isMobile ? '200px' : '0px',
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		overflowY: 'auto'
	},
	footer: {
		zIndex: 1000,
		height: isMobile ? '10vh' : '10vh',
		background: '#2aac9cff',
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	}
}
//#endregion