/**
*  
*
* @author Juan Rico
* 
* @module SoporteVitalBasico 
*  
* @description  Este módulo define el la pagina simulacion basica.
* permite realizar la simulacion de soporte vital basico + DEA
* 
*/

/**
 * Importaciones necesarias para el la pagina de informes.
 */


import React, { useState, useEffect, useContext, useMemo } from 'react' // Importa React y hooks.
import axios from 'axios'; // Librería para realizar solicitudes HTTP.
import { isMobile, isDesktop, isTablet } from 'react-device-detect'  // functiones para detectar tipos de dispositivos
import { faStopwatch } from '@fortawesome/free-solid-svg-icons' // Íconos específicos para el componente.
import './basic.css'  // importacion de la hoja de estilos para esta pagina
import {
    iconAvisaEquipo,
    iconCompresiones,
    iconDea,
    iconNoResponde,
    iconNotas,
    iconVentilaciones,
    iconReanimacion,
    iconPulmones,
    iconViaAerea,
    logoBasico,
} from '../../helpers/ImagesCache' //Iconos especificos cargados en cache por el desarrolador
import Header from '../../components/Header/Header' // Componente de la cabezera de la pagina.
import NSSwitcher from '../../components/NSButton/Types/Switcher' // Componente para botones de tipo switch
import NSBoolean from '../../components/NSButton/Types/Boolean' // Componente para botones de tipo Boleano
import NSTimer from '../../components/NSButton/Types/Timer' // Componente para botones de tipo Tiempo
import NSCounter from '../../components/NSButton/Types/Counter' // Componente para botones de tipo Contadores
import NSIndicator from '../../components/NSButton/Types/Indicator' // Componente para botones de tipo indicador
import NSSimpleButton from '../../components/NSButton/Types/SimpleButton'  // Componente para botones de tipo simple
import DialogVentilaciones from '../../components/DialogVentilaciones/DialogVentilaciones' // Componente para Pop-up de las ventilaciones
import DialogDownNotes from '../../components/DialogDownNotes/DialogDownNotes'// Componente para Pop-up de las notas
import DialogHabilidades from '../../components/DialogHabilidades/DialogHabilidades'; // Componente para Pop-up de las habilidades no tecnicas
import { GlobalContext, TimelineContext } from '../../helpers/Context'  // Contextos de linea de tiempo y global.
import { BASEURLAPI } from '../../helpers/Constants'; // URL base para las solicitudes API.
import Loader from '../../components/Loader/Loader'; // Loader para cargas en procesos
import { TimelineDefault } from '../../helpers/Constants'; //importa el contexto por defecto 
import DialogNoTecnicas from '../../components/DialogNoTecnicas/DialogNoTecnicas';
import DialogCalidadRcp from '../../components/DialogCalidadRcp/DialogCalidadRcp';
import DialogMedirAmbas from '../../components/DialogMedirAmbas/DialogMedirAmbas';
/**
 * Componente principal que maneja la visualización de simulacion basica.
 * 
 * @function Basic
 * @returns {JSX.Element} Un elemento que representa la interfaz de simulacion basica.
 */
const Basic = () => {
    /**
     * Contexto global
     * @type {Object}
     */
    const contextGlobal = useContext(GlobalContext);

    /**
     * Contexto de la linea de tiempo.
     * @type {Object}
     */
    const ctxTimeline = useContext(TimelineContext);

    /**
     * Usa la función `stylesSheet` para obtener los estilos del componente
     * y memoriza el resultado para evitar recalculos innecesarios.
     *
     * @returns {Object} Un objeto que contiene los estilos del componente.
     */
    const styles = useMemo(() => stylesSheet(), [isTablet, isDesktop, isMobile]);

    /**
    * Estado que almacena el ID del usuario actual.
    * @type {number}
    * @default 2 - user id por defecto
    */
    const [userId, setUserId] = useState(2)

    /**
    * Estado que almacena si el caso ha sido detenido.
    * @type {boolean}
    * @default false - al iniciar esta desactivado
    */
    const [ForceStopCase, setForceStopCase] = useState(false)

    /**
     * Estado que almacena si el caso ha sido iniciado.
     * @type {boolean}
     * @default false - al iniciar esta desactivado
     */
    const [isStarted, setIsStarted] = useState(false)

    /**
     * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
     * @type {boolean}
     * @default false - al iniciar esta oculto
     */
    const [isLoading, setIsLoading] = useState(false)

    /**
     * Estado que almacena si hay algun recurso en proceso para bloquear la pantalla a interacciones
     * @type {string}
     * @default 'Guardando informe...' - al iniciar tiene el texto 'Guardando informe...'
     */
    const [loadingText, setLoadingText] = useState('Guardando informe...')

    /**
     * Estado que almacena si se puede realizar un pedido de reporte.
     * @type {boolean}
     * @default false - al iniciar esta desactivado
     */
    const [canGetReport, setCanGetReport] = useState(false)

    /**
     * Estado que almacena el valor del contador de tiempo para verificar si se puede generar un reporte
     * @type {number}
     * @default 0 - al iniciar su valor es 0
     */
    const [timerCounter, setTimerCounter] = useState(0)

    /**
     * Estado que almacena si el pop-up principal de medir compresiones esta activo
     * @type {boolean}
     * @default true - al iniciar esta activado
     */
    const [isPopupActive, setIsPopupActive] = useState(true)

    /**
     * Estado que almacena si el pop-up principal de notas esta activo
     * @type {boolean}
     * @default false - al iniciar esta oculto
     */
    const [isPopupNotasActive, setIsPopupNotasActive] = useState(false)

    /**
     * Estado que almacena si el pop-up de las habilidades no tecnicas
     * @type {boolean}
     * @default true - al iniciar esta activo pero no se muestra
     */
    const [isPopupHabilidadesActive, setIsPopupHabilidadesActive] = useState(true)

    /**
     * Estado que almacena si hay que medir las compresiones 
     * @type {boolean}
     * @default false - al iniciar no se miden compresiones 
     */
    const [compresions, setCompresions] = useState(false)

    /**
     * Estado que almacena el texto de las notas ingresadas
     * @type {string}
     * @default '' - al iniciar no hay notas
     */
    const [notas, setNotas] = useState('')

    const [isPopUpNoTecnicas, setIsPopUpNoTecnicas] = useState(false)
	const [isPopUpCalidadRcp, setIsPopUpCalidadRcp] = useState(false)
	const [isPopUpMedirAmbas, setIsPopUpMedirAmbas] = useState(false)


    /**
     * Verifica si el informe ya se ha guardado en la base de datos.
     * @async
     * @function reportExist
     * @todo
     * api que verifique la existencia de un reporte
     *  
     */
    const reportExist = async () => {

        const url = `${BASEURLAPI}/caseExist` //la url para la solicitud
        let result = false
        await axios.get(url)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.result == true) {
                        result = true
                    }
                }
            })
            .catch((error) => {
                console.log('hubo un error', error)
            })

        return result
    }

    /**
    * Guarda el ingorme en la base de datos una vez finalizado el caso.
    * 
    * @function SaveReport
    * @todo
    * verificar si el informe ya existe
    */
    const SaveReport = async () => {
        const exist = await reportExist()
        if (!exist) {
            setLoadingText('Espere mientras el informe es guardado...')
            setIsLoading(true)
            const url = `${BASEURLAPI}/createCase`
            const formData = new FormData()
            formData.append('userId', userId)
            formData.append('name', `TESTCASE-${+new Date()} (Basico)`)
            formData.append('timeline', JSON.stringify(ctxTimeline))
            formData.append('duration', 0)
            axios.post(url, formData)
                .then((response) => {
                    if (response.status == 200) {
                        console.log(response.data)

                    } else {

                    }

                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log('hubo un error', error)
                    setIsLoading(false)
                })
        }
    }

    /**
     * Inicia el caso
     * 
     * @function handlerStart
     */
    const handlerStart = () => setIsStarted(true)

    /**
     * Detiene el caso
     * 
     * @function handlerStop
     */
    const handlerStop = () => setIsStarted(false)

    /**
     * Fuerza a la detencion y deshabilitacion de todos los componentes Timer y Counter
     * 
     * @function handlerForceStopCase
     */
    const handlerForceStopCase = (value) => setForceStopCase(value)


    /**
     * Activa la medicion de compresiones y oculta el Pop-up de compresiones
     * 
     * @function handlerCompresions
     */
    const handlerCompresions = () => {
        setCompresions(true)
        setIsPopupActive(false)
    }

    /**
     * Oculta el Pop-up de compresiones
     * 
     * @function handlerCloseDialog
     */
    const handlerCloseDialog = () => {
        setIsPopupActive(false)
    }

    /**
    * Alterna el estado del el Pop-up de notas
    * 
    * @function handlerNotasDialog
    */
    const handlerNotasDialog = () => {
        setIsPopupNotasActive(!isPopupNotasActive)
    }

    /**
     * Hook que se ejecuta al montar el componente y cada vez que las notas son actualizado.
     */
    useEffect(() => { setNotas(ctxTimeline.Notas) }, [ctxTimeline.Notas])

    /**
     * Hook que se ejecuta al montar el componente.
     * para reiniciar el timeline de contexto
     */
    useEffect(() => {
        Object.keys(ctxTimeline).map((key) => {
            ctxTimeline[key] = TimelineDefault[key]
        })
        ctxTimeline.notas = ''
    }, [])

    /**
     * Hook que se ejecuta al montar el componente y cada vez que canGetReport es actualizado .
     */
    useEffect(() => {
        if (canGetReport) {
            //aqui deberiamos activar lla generacion de reportes
        }
    }, [canGetReport])

    /**
    * Hook que se ejecuta al montar el componente y cada vez que isStarted es actualizado .
    */
    useEffect(() => {
        setCanGetReport(timerCounter > 0)
    }, [isStarted])

    /**
     * Hook que se ejecuta al montar el componente para verificar el contexto del timeline .
     */
    useEffect(() => {
        console.log("el contexto fue reseteado: ", ctxTimeline)
    }, [ctxTimeline])

    useEffect(() => {
		ctxTimeline.type = 'basic'
	}, [])

    const handlerDialogNoTecnicas = () => {
		setIsPopUpNoTecnicas(true)
	}

	const handlerDialogCalidadRcp = () => {
		setIsPopUpCalidadRcp(true)
	}

	const handlerDialogMedirAmbas = () => {
		setIsPopUpMedirAmbas(true)
	}

	const handlerDialogNoTecnicasStop = () => {
		setIsPopUpNoTecnicas(false)
		setIsPopupHabilidadesActive(false)
	}

	const handlerDialogCalidadRcpStop = () => {
		setIsPopUpCalidadRcp(false)
		setIsPopupHabilidadesActive(false)
	}

	const handlerMedirAmbasStop = () => {
		setIsPopUpMedirAmbas(false)
        setIsPopUpNoTecnicas(false)
        setIsPopupHabilidadesActive(true)
        
	}

    const handleSaveAndCloseNotecnicas = () => {
		setIsPopUpNoTecnicas(false)
		SaveReport()
	}
	const handleSaveAndCloseCalidadRcp = () => {
		setIsPopUpCalidadRcp(false)
		SaveReport()
	}

	const handleVolverNoTecnicas = () => {
		setIsPopUpNoTecnicas(false)
        setIsPopupHabilidadesActive(true)
	}

	const handleVolverCalidadRcp = () => {
		setIsPopUpCalidadRcp(false)
        setIsPopupHabilidadesActive(true)
	}

    return (<>
        <Loader status={isLoading} text={loadingText} />
        <div style={styles.main} >
            <Header options={{}} minimal={true} logo={logoBasico} />
            <div className='flex gap-8 my-3'>
                <NSTimer
                    milliseconds={false}
                    options={{ titleOn: 'Parar caso', titleOff: 'Iniciar caso' }}
                    onStartTimer={(value) => {
                        ctxTimeline.startCase = value?.startAt
                        ctxTimeline.history.push({
                            time: value?.startAt,
                            title: 'Inicio de Caso',
                            object: 'startCase'
                        })
                        handlerStart()
                    }}
                    onStopTimer={(value) => {
                        handlerForceStopCase(true)
                        ctxTimeline.startCase = value?.startAt
                        ctxTimeline.stopCase = value?.stopAt
                        ctxTimeline.history.push({
                            time: value?.stopAt,
                            title: 'Fin del Caso',
                            object: 'stopCase'
                        })
                        handlerStop()

                    }}
                    forceDisable={ForceStopCase}
                    forceStop={ForceStopCase}
                />
                <NSTimer
                    milliseconds={false}
                    options={
                        {
                            titleOn: 'RCE',
                            titleOff: 'Inicio PCR'
                        }}
                    onStopTimer={(value) => {
                        ctxTimeline.RCE.push(
                            {
                                start: value?.startAt,
                                stop: value?.stopAt
                            }
                        )
                        ctxTimeline.history.push({
                            time: value?.stopAt,
                            title: 'RCE',
                            object: 'RCE'
                        })
                    }}
                    onStartTimer={(value) => {
                        ctxTimeline.PCR.push(
                            {
                                start: value?.startAt
                            }
                        )
                        ctxTimeline.history.push({
                            time: value?.startAt,
                            title: 'Inicio PCR',
                            object: 'PCR'
                        })
                    }}
                    forceDisable={!isStarted}
                    forceStop={ForceStopCase}
                />
            </div>
            <div className='w-full' style={styles.bodyContent} >
                <div className='p-2 w-full  h-full' style={styles.switcherSection}>
                    <div className='w-[83%] flex justify-between w-full '>
                        <NSSwitcher
                            options={{
                                title: 'Escenario seguro',
                                subtitleActive: '',
                                subtitle: '',
                                initialState: false
                            }}
                            onClick={(value) => {
                                ctxTimeline.isSafeScene = value
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title: `Escenario seguro`,
                                    object: 'isSafeScene'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSSwitcher
                            options={{
                                title: 'Grita ayuda',
                                subtitleActive: '',
                                subtitle: '',
                                initialState: false
                            }}
                            onClick={(value) => {
                                ctxTimeline.shoutedHelp = value
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title: `Grita ayuda`,
                                    object: 'shoutedHelp'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSSwitcher
                            options={{
                                title: 'Canula de Guedel',
                                subtitleActive: '',
                                subtitle: '',
                                initialState: false
                            }}
                            onClick={(value) => {
                                ctxTimeline.guedelCannula = value
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title: `Cannula de guedel`,
                                    object: 'guedelCannula'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                    </div>
                    <div className='h-full' style={styles.buttonsSeccion}  >
                        <NSBoolean
                            title='Comprueba consciencia'
                            icon={iconNoResponde}
                            initialState={false}
                            onClick={(value) => {
                                if (value == true) {
                                    ctxTimeline.consistency = +new Date()
                                }
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title: `Comprueba Consciencia`,
                                    object: 'consistency'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSCounter
                            options={{
                                titleStart: 'Abre via aerea',
                                titleOff: 'Abre via aerea',
                                titleOn: 'Abre via aerea',
                                icon: iconViaAerea,
                                initialState: false,
                                type: 'restart',
                                onlyCounter: true
                            }}
                            sum={1}
                            onStopTimer={(value) => {
                                ctxTimeline.openAirWay.push(
                                    {
                                        start: value?.startAt,
                                        stop: value?.stopAt
                                    }
                                )
                                ctxTimeline.history.push({
                                    time: value?.stopAt,
                                    title: `Abre via respiratoria`,
                                    object: 'openAirWay'
                                })
                            }}
                            onStartTimer={(value) => {
                                ctxTimeline.history.push({
                                    time: value?.startAt,
                                    title: `Abre via respiratoria`,
                                    object: 'openAirWay'
                                })
                            }}
                            list={ctxTimeline.openAirWay}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSCounter
                            options={{
                                titleStart: 'Comprueba ventilacion',
                                titleOff: 'Comprueba ventilacion',
                                titleOn: 'Comprueba ventilacion',
                                icon: iconPulmones,
                                initialState: false,
                                type: 'restart',
                                onlyCounter: true
                            }}
                            sum={1}
                            onStopTimer={(value) => {
                                ctxTimeline.checkVentilation.push(
                                    {
                                        start: value?.startAt,
                                        stop: value?.stopAt
                                    }
                                )
                                ctxTimeline.history.push({
                                    time: value?.stopAt,
                                    title: `Comprueba ventilación`,
                                    object: 'checkVentilation'
                                })
                            }}
                            onStartTimer={(value) => {
                                ctxTimeline.history.push({
                                    time: value?.startAt,
                                    title: `Comprueba ventilacion`,
                                    object: 'checkVentilation'
                                })
                            }}
                            list={ctxTimeline.checkVentilation}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSBoolean
                            title='Avisa equipo de emergencias'
                            icon={iconAvisaEquipo}
                            initialState={false}
                            onClick={(value) => {
                                if (value == true) {
                                    ctxTimeline.notifiesEmergencyTeam = +new Date()
                                }
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title: `Avisa equipo de emergencias`,
                                    object: 'notifiesEmergencyTeam'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />


                        {
                            compresions &&
                            <NSCounter
                                options={{
                                    titleStart: 'Inicia compresiones',
                                    titleOff: 'Inicia compresiones',
                                    titleOn: 'Detiene compresiones',
                                    icon: iconCompresiones,
                                    initialState: false,
                                    onlyCounter: true
                                }}
                                sum={1}
                                onStopTimer={(value) => {
                                    ctxTimeline.compressions.push(
                                        {
                                            start: value?.startAt,
                                            stop: value?.stopAt
                                        }
                                    )
                                    ctxTimeline.history.push({
                                        time: value?.stopAt,
                                        title: `Detiene Compresiones`,
                                        object: 'compressions'
                                    })
                                }}
                                onStartTimer={(value) => {
                                    ctxTimeline.history.push({
                                        time: value?.startAt,
                                        title: `Inicia Compresiones`,
                                        object: 'compressions'
                                    })
                                }}
                                list={ctxTimeline.compressions}
                                forceDisable={!isStarted}
                                forceStop={ForceStopCase}
                            />
                        }
                        {
                            compresions &&
                            <NSCounter
                                options={{
                                    titleStart: 'Inicia ventilaciones',
                                    titleOff: 'Inicia ventilaciones',
                                    titleOn: 'Detiene ventilaciones',
                                    icon: iconVentilaciones,
                                    initialState: false,
                                    onlyCounter: true
                                }}
                                sum={1}
                                onStopTimer={(value) => {
                                    ctxTimeline.vents.push(
                                        {
                                            start: value?.startAt,
                                            stop: value?.stopAt
                                        }
                                    )
                                    ctxTimeline.history.push({
                                        time: value?.stopAt,
                                        title: `Detiene Ventilaciones`,
                                        object: 'vents'
                                    })
                                }}
                                onStartTimer={(value) => {
                                    ctxTimeline.history.push({
                                        time: value?.startAt,
                                        title: `Inicia Ventilaciones`,
                                        object: 'vents'
                                    })
                                }}
                                list={ctxTimeline.vents}
                                forceDisable={!isStarted}
                                forceStop={ForceStopCase}
                            />
                        }
                        {
                            !compresions &&
                            <NSCounter
                                options={{
                                    titleStart: 'Inicia RCP',
                                    titleOff: 'Inicia RCP',
                                    titleOn: 'Detiene RCP',
                                    icon: iconReanimacion,
                                    initialState: false
                                }}
                                sum={1}
                                onStopTimer={(value) => {
                                    ctxTimeline.RCP.push(
                                        {
                                            start: value?.startAt,
                                            stop: value?.stopAt
                                        }
                                    )
                                    ctxTimeline.history.push({
                                        time: value?.stopAt,
                                        title: `Detiene RCP`,
                                        object: 'RCP'
                                    })
                                }}
                                onStartTimer={(value) => {
                                    ctxTimeline.history.push({
                                        time: value?.startAt,
                                        title: `Inicia RCP`,
                                        object: 'RCP'
                                    })
                                }}
                                list={ctxTimeline.RCP}
                                forceDisable={!isStarted}
                                forceStop={ForceStopCase}
                            />
                        }
                        <NSCounter
                            options={{
                                titleStart: 'Coloca DEA',
                                titleOff: 'DESCARGA',
                                titleOn: 'DESCARGA',
                                icon: iconDea,
                                initialState: false,
                                type: 'restart',
                                disableInitialTimer: true,
                            }}
                            sum={0}
                            onStopTimer={(value) => {
                                ctxTimeline.DEA.push(
                                    {
                                        start: value?.startAt,
                                        stop: value?.stopAt
                                    }
                                )
                                ctxTimeline.history.push({
                                    time: value?.stopAt,
                                    title: `DESCARGA`,
                                    object: 'DEA',
                                    colorPrimario: '#F4D947',
                                    
                                })
                            }}
                            onStartTimer={(value) => {
                                ctxTimeline.history.push({
                                    time: value?.startAt,
                                    title: `Coloca DEA`,
                                    object: 'DEA',
                                    hideOnReport: true
                                    //colorPrimario: '#F4D947',
                                })
                            }}
                            list={ctxTimeline.DEA}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSBoolean
                            title='Posicion lateral de seguridad'
                            icon={null}
                            initialState={false}
                            onClick={(value) => {
                                if (value == true) {
                                    ctxTimeline.lateralSafetyPosition = +new Date()
                                }
                                ctxTimeline.history.push({
                                    time: +new Date(),
                                    title: `Posición lateral de seguridad`,
                                    object: 'lateralSafetyPosition'
                                })
                            }}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                        <NSIndicator
                            options={{
                                title: 'Notas',
                                icon: iconNotas,
                                values: [
                                    { status: notas == '' ? false : true },
                                ],
                                minimal: true,
                                initialState: true
                            }}
                            onClick={handlerNotasDialog}
                            forceDisable={!isStarted}
                            forceStop={ForceStopCase}
                        />
                    </div>
                </div>
            </div>
            <div className='w-full' style={styles.footer} >
                <NSSimpleButton
                    title='Informe' 
                    initialState={ForceStopCase}
                    callBack={SaveReport}
                />
            </div>
        </div>
        <DialogVentilaciones
            text={'Desea medir compresiones y ventilaciones?'}
            icon={faStopwatch}
            callbackConfirm={handlerCompresions}
            callbackCancel={handlerCloseDialog}
            status={isPopupActive}
        />
        {
            isPopupNotasActive &&
            <DialogDownNotes
                text={'Notas'}
                icon={iconNotas} 
                callbackCancel={handlerNotasDialog}
                status={isPopupNotasActive}
                callbackConfirm={(text)=> {
                    ctxTimeline.history.push({
                        time: +new Date(),
                        title: 'Notas: ',
                        object: 'Notas',
                        value: text,
                        colorPrimario: '#2AAC9C'
                    })
                    handlerNotasDialog()
                }}
            />
        }
        <DialogHabilidades
            text={'¿Desea medir las habilidades no técnicas y/o la calidad de las RCP?'}
            icon={faStopwatch}
            callbackConfirm={handlerStop}
            callbackCancel={() => setIsPopupHabilidadesActive(false)}
            status={ForceStopCase && isPopupHabilidadesActive}
            action01={() => {
                handlerDialogNoTecnicas()
                setIsPopupHabilidadesActive(false)
            }}
            action02={() => {
                handlerDialogCalidadRcp() 
                setIsPopupHabilidadesActive(false)
            }}
            action03={() => {
                handlerDialogMedirAmbas()
                setIsPopupHabilidadesActive(false)
            }}
            action04={() => {
                SaveReport()
                setIsPopupHabilidadesActive(false)
            }}
        />

        <DialogNoTecnicas
            callbackConfirm={()=> {
                
                SaveReport()
                setIsPopUpNoTecnicas(false)
            }}
            callbackCancel={() => handlerDialogNoTecnicasStop()}
            status={isPopUpNoTecnicas}
            volverNoTecnicas={() => handleVolverNoTecnicas()}
            actionNoTecnicas={() => handleSaveAndCloseNotecnicas()} 
            saveAnd={"Guardar y generar informe"}

        />

        <DialogCalidadRcp
            callbackConfirm={()=> { 
                SaveReport()
                setIsPopUpCalidadRcp(false)
            }}
            callbackCancel={() => handlerDialogCalidadRcpStop()}
            status={isPopUpCalidadRcp}
            volverCalidadRcp={() => handleVolverCalidadRcp() }
            actionCalidadRcp={() => handleSaveAndCloseCalidadRcp()}
            saveAnd={"Guardar y generar informe"}
        />


        <DialogMedirAmbas
            callbackConfirm={handlerDialogMedirAmbas}
            callbackCancel={() => handlerMedirAmbasStop()} 
            status={isPopUpMedirAmbas}
        />
    </>
    )
}
export default Basic


/**
 * Genera un objeto de estilos para el componente basado en el estado actual
 * de la vista (tableta, escritorio, móvil).
 * @function stylesSheet
 * @returns {Object} Un objeto que contiene los estilos para el componente.
 */
const stylesSheet = () => ({
    main: {
        maxWidth: isTablet ? '100%' : isDesktop ? '1280px' : '700px', // verifica que tipo de dispositivo es
        maxHeight: '100vh',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    bodyContent: {
        display: 'flex',
        zIndex: 0,
        background: '#eaf7f5',
        overflow: 'hidden',
        justifyContent: 'center',
        height: '100%',
        minHeight: '80vh',
    },
    switcherSection: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    buttonsSeccion: {
        gap: isMobile ? '2%' : '1%',
        justifyContent: 'center',
        marginBottom: isMobile ? '200px' : '0px', // agrega un margin cuando es mobile
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        overflowY: 'auto',
    },
    footer: {
        zIndex: 1000,
        height: isMobile ? '10%' : '12%', // altura variable si es mobile o otro tipo de dispositivo
        background: '#2aac9cff',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
});