/**
*  
*
* @author Juan Rico
* 
* @module Informes
*  
* @description Este módulo define el la pagina de Informes o `Reports`, que permite visualizar y gestionar informes.
* El componente se encarga de la obtención y eliminación de informes, así como de la 
* visualización de informes individuales con sus respectivos detalles.
* 
*/

/**
 * Importaciones necesarias para el la pagina de informes.
 */
import React, { useContext, useEffect, useState } from 'react' // Importa React y hooks.
import axios from 'axios'; // Librería para realizar solicitudes HTTP.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // Componente para usar íconos de FontAwesome. 
import { 
    faBolt,  
    faArrowDown, 
    faArrowLeft, 
    faArrowUp, 
    faClock, 
    faRefresh, 
    faStopwatch,  
    faTrashAlt 
} from '@fortawesome/free-solid-svg-icons';  // Íconos específicos para el componente.
import {  
    iconAdrenalina, 
    iconCompresiones, 
    iconDesfibrilacion, 
    iconInforme, 
    iconNotas 
} from '../../helpers/ImagesCache'; //Iconos especificos cargados en cache por el desarrolador
import './reports.css' // importacion de la hoja de estilos para esta pagina
import Header from '../../components/Header/Header' // Componente de la cabezera de la pagina.
import Timeline from '../../components/Timeline/Timeline'; // Componente para mostrar la linea de tiempo
import { TimelineContext } from '../../helpers/Context'; // Contexto que proporciona información sobre la línea de tiempo.
import { completeTimerFormat, formatTimer, timerFormat, TimerFormatResta } from '../../helpers/helpers'; // funcion para formatear el tiempo 
import { BASEURLAPI } from '../../helpers/Constants'; // URL base para las solicitudes API.
import NSListItem from '../../components/NSButton/Types/ListItem' // Componente para mostrar cada informe en la lista.
import GraphicalReport from '../../components/GraphicalReport/GraphicalReport.';
import HistoryText from '../../components/HistoryText/HistoryText';
/**
 * Componente principal que maneja la visualización de informes.
 * 
 * @function Reports
 * @returns {JSX.Element} Un elemento que representa la interfaz de informes.
 */
 
const Reports = ({ preview }) => {   

    /**
     * Contexto de la linea de tiempo.
     * @type {Object}
     */
    const ctxTimeline = useContext( TimelineContext );

    /**
     * Estado que almacena el ID del informe actual seleccionado.
     * @type {number}
     */
    const [currentReport, setCurrentReport ] = useState(0)

    /**
     * Estado que almacena los detalles del informe seleccionado.
     * @type {Object}
     */
    const [selectedReport, setSelectedReport ] = useState({})

    /**
     * Estado que almacena el ID del usuario actual.
     * @type {number}
     */
    const [userId, setUserId ] = useState(2)

    /**
     * Estado que almacena la lista de informes obtenidos.
     * @type {Array<Object>}
     */
    const [reports, setReports] = useState([])

    /**
     * Estado que indica si los informes se están cargando.
     * @type {boolean}
     */
    const [isLoading, setIsLoading] = useState(true)

     

    /**
     * Componente que renderiza la lista de informes.
     * 
     * @function ListOfReports
     * @returns {JSX.Element} Un elemento que muestra la lista de informes.
     */
    const ListOfReports = () => { 
        return (
            <div className='flex flex-col  w-full'>   
                {  reports.length > 0 ? 
                    reports.map(
                        (item,index)=>{

                           return (
                                <NSListItem  
                                    key={'reports-' + item.id}
                                    options={item}  
                                    onClick={ (item) => viewReport(item) } 
                                    onDelete={deleteCase}
                                /> 
                            )
                        } 
                    ) :
                    <span className='ml-10 text-xl text-[#f009] font-bold'>
                        { 
                            isLoading ? 
                            'Cargando datos':
                            'No hay Informes creados'
                        } 
                    </span>
                } 
            </div>
        )
    }

     
    /**
     * Maneja la visualización de un informe específico.
     * 
     * @function viewReport
     * @param {number} reportId - ID del informe a visualizar.
     */
    const viewReport = (reportId) => {
        setCurrentReport(reportId)
    }

    /**
     * Regresa al estado inicial (sin informe seleccionado).
     * 
     * @function goToRoot
     */
    const goToRoot = () => {
        setCurrentReport(0)
    }

    /**
     * Obtiene el tiempo total de un objeto específico.
     * 
     * @function getTotalTimeOf
     * @param {string} object - El nombre del objeto del que se desea obtener el tiempo.
     * @returns {string} El tiempo total en formato de minutos y segundos.
     */
    const getTotalTimeOf = ( object ) => {
        let timeTemp = 0
        selectedReport?.timeline?.[object]?.map((item)=>{
            const time = item.stop - item.start 
            timeTemp += time
        })
        const totalTime = new Date(timeTemp)
        let minutes = totalTime.getMinutes()
        let seconds = totalTime.getSeconds() 
        seconds = seconds < 10 ? '0' + seconds : seconds 
        return  `${minutes}' ${seconds}"`
    } 

    /**
     * Obtiene el tiempo total de un objeto específico.
     * 
     * @function getTotalTime
     * @param {string} object - El nombre del objeto del que se desea obtener el tiempo total.
     * @returns {number} El tiempo total en milisegundos.
     */
    const getTotalTime = ( object ) => {
        let timeTemp = 0
        selectedReport?.timeline?.[object]?.map((item)=>{
            const time = item.stop - item.start 
            timeTemp += time
        }) 
        return  timeTemp
    } 

    /**
     * Suma el tiempo de un array de objetos.
     * 
     * @function getSumaTime
     * @param {Array<number>} objects - Array de tiempos a sumar.
     * @returns {string} El tiempo total en formato de minutos y segundos.
     */
    const getSumaTime = ( objects ) => {
        let timeTemp = 0
        objects.map((item)=>{ 
            timeTemp += item
        })
        const totalTime = new Date(timeTemp)
        let minutes = totalTime.getMinutes()
        let seconds = totalTime.getSeconds() 
        seconds = seconds < 10 ? '0' + seconds : seconds 
        return  `${minutes}' ${seconds}"`
    } 

    /**
     * Obtiene el tiempo medio de un objeto específico.
     * 
     * @function getMediaTimeOf
     * @param {string} object - El nombre del objeto del que se desea obtener el tiempo medio.
     * @returns {string} El tiempo medio en formato de minutos y segundos.
     */
    const getMediaTimeOf = ( object, activeUnique = false ) => {
        let timeTemp = 0
        let timesTemporal = []
        const total =  selectedReport?.timeline?.[`${object}`]?.length || 0
        const unique =  selectedReport?.timeline?.[`${object}`] || []
        if(total > 1){ 
            
            selectedReport?.timeline?.[`${object}`]?.sort((a,b)=>{
                const time = a.start - b.start 
                timesTemporal.push(time)
            })
            timesTemporal.forEach( item => timeTemp += item )
            let mediaTemp = timeTemp / timesTemporal.length
            const totalTime = new Date(mediaTemp)
            let minutes = totalTime.getMinutes()
            let seconds = totalTime.getSeconds() 
            seconds = seconds < 10 ? '0' + seconds : seconds 
            return  `${minutes}' ${seconds}"`
        } else {
            if( activeUnique && total == 1){
                const totalTime = new Date(unique[0].start - selectedReport?.timeline?.startCase)
                let minutes = totalTime.getMinutes()
                let seconds = totalTime.getSeconds() 
                return  `${minutes}' ${seconds}"`
            } 
            return  `00' 00"`
        }
    } 


    /**
     * Obtiene el tiempo medio de un objeto específico.
     * 
     * @function getMediaTimeOfHistory
     * @param {string} object - El nombre del objeto del que se desea obtener el tiempo medio.
     * @returns {string} El tiempo medio en formato de minutos y segundos.
     */
    const getMediaTimeOfHistory = ( object, activeUnique = false ) => {
        let timeTemp = 0
        let timesTemporal = [] 
        const list =  selectedReport?.timeline?.history?.filter(item => item.object == object) || []
        if( list.length > 1 ){  
            list.sort((a,b)=>{
                const time = a.time - b.time 
                timesTemporal.push(time)
            })
            timesTemporal.forEach( item => timeTemp += item )
            let mediaTemp = timeTemp / timesTemporal.length
            const totalTime = new Date(mediaTemp)
            let minutes = totalTime.getMinutes()
            let seconds = totalTime.getSeconds() 
            seconds = seconds < 10 ? '0' + seconds : seconds 
            return  `${minutes}' ${seconds}"`
        } else {
            if( activeUnique && list.length == 1){
                const totalTime = new Date(list[0].time - selectedReport?.timeline?.startCase)
                let minutes = totalTime.getMinutes()
                let seconds = totalTime.getSeconds() 
                return  `${minutes}' ${seconds}"`
            } 
            return  `00' 00"`
        }
    } 

    

    /**
     * Obtiene todos los informes del usuario.
     * 
     * @function getAllReports
     */
    const getAllReports = () => {
        
        setIsLoading(true)
        const url = `${BASEURLAPI}/getCases/${userId}`
        axios.get(url,null,{
            headers:{
                'Access-Control-Allow-Origin':'*'
            }
        })
        .then((response)=>{
            if(response.status == 200){ 
                setReports(response.data.data)
                console.log(response.data.message)
            }
            setIsLoading(false)
        })
        .catch((error)=>{
            console.log('hubo un error',error)
            setIsLoading(false)
        })
    }

    /**
     * Elimina un caso específico.
     * 
     * @function deleteCase
     * @param {number} id - ID del caso a eliminar.
     */
    const deleteCase = (id) => {
        const report = reports.find(a=> a.id == id )
        const sure = confirm(`Desea eliminar el reporte ${report?.name}`)
        if(sure){ 
            const url = `${BASEURLAPI}/deleteCase/${userId}/${id}`
            
            axios.get(url)
            .then((response)=>{
                if(response.status == 200){
                    console.log(response.data) 
                } 
                getAllReports()
                if(currentReport>0){
                    setCurrentReport(0)
                }
            })
            .catch((error)=>{
                console.log('hubo un error',error) 
                getAllReports()
            })
        }
    }

    const getSelectedReport = () =>{
        const reporteActual = reports.filter((item)=>item.id == currentReport)
        if(reporteActual.length > 0){ 
            const currentTempReport = {
                ...reporteActual[0], 
                'timeline': JSON.parse(reporteActual[0].timeline)
            }

            setSelectedReport( currentTempReport)
            return( currentTempReport )
        }
        return {}
    }

    /**
     * Hook que se ejecuta al montar el componente y cada vez que userId es actualizado.
     */
    useEffect(()=>{
        getAllReports()
    },[userId])

    /**
     * Hook que se ejecuta al montar el componente y cada vez que currentReport es actualizado.
     */
    useEffect(()=>{
        getSelectedReport() 
    },[currentReport])

    useEffect(()=>{
        console.log('selectedReport',selectedReport)
    },[selectedReport])

    return (
        <div className='overflow-x-hidden right-[auto] left-[auto] flex flex-col bg-white  h-[100vh]  max-w-[1280px] items-center justify-start w-[100vw]'>
            
            <Header options={{}} minimal className='' />
            
            <div className=' z-[0] px-4  flex flex-col  bg-[#fff] flex w-full items-center justify-start gap-5 '>
                <div className='flex w-full items-center justify-between gap-4 font-[600] text-[#0007] mx-10'>
                    <div className='flex w-full items-center gap-4'>
                        { currentReport > 0 && <FontAwesomeIcon className='cursor-pointer' icon={faArrowLeft} onClick={goToRoot} /> }
                        <img src={iconInforme} alt={iconInforme} />
                        <span>INFORMES</span> 
                    </div>
                    <div className='flex gap-4 cursor-pointer items-center justify-center h-full w-10 text-2xl text-[#2aac9c]' >
                        { currentReport > 0 && 
                            <FontAwesomeIcon 
                                className='hover:bg-red-100 cursor-pointer hover:text-red-500 flex text-black' 
                                icon={faTrashAlt} 
                                onClick={()=>deleteCase(selectedReport?.id)}/> 
                        }
                        <FontAwesomeIcon 
                            className='transform hover:rotate-[360deg] transition-ease  transition-[2s] ' 
                            icon={faRefresh } 
                            onClick={getAllReports} />
                    </div>
                </div> 
            </div>
            <div className=' z-[0] p-4  flex flex-col  bg-[#fff] flex w-full items-center justify-start gap-5 '>
                
                
                { currentReport == 0 && <ListOfReports /> }  
                { 
                    currentReport > 0 &&
                    <GraphicalReport   
                        isNameEditable = { false }
                        selectedReport = { getSelectedReport }
                        historyReport = { true } 
                        minimized={false}
                    /> } 
                 
            </div>
            
        </div>
    )
}
export default Reports
