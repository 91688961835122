 
/**
 * Importaciones necesarias para el la pagina de informes.
 */
import React, { useContext, useEffect, useState } from 'react' // Importa React y hooks.
import axios from 'axios'; // Librería para realizar solicitudes HTTP.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // Componente para usar íconos de FontAwesome. 
import {  
    faArrowDown,  
    faArrowUp,  
} from '@fortawesome/free-solid-svg-icons';  // Íconos específicos para el componente.
import { timerFormat } from '../../helpers/helpers'; // funcion para formatear el tiempo  
/**
 * Componente principal que maneja la visualización de informes.
 * 
 * @function HistoryText
 * @returns {JSX.Element} Un elemento que representa la interfaz de informes.
 */
 


const HistoryText = ({  selectedReport, minimized, onChangeMinimized}) => {


     /**
     * Estado que indica si se está mostrando el informe completo.
     * @type {boolean}
     */
     const [isFullReport, setIsFullReport] = useState(minimized)
    /**
         * Alterna la visualización del informe completo.
         * 
         * @function handlerFullReport
         */
    const handlerFullReport = ( ) => {
        if(onChangeMinimized){ 
            onChangeMinimized(!minimized)
        }
    }
    let startCase = false
    return (
        <>
       
        <div 
            style={{  minHeight: '10vh' }} 
            className='flex flex-col mt-0 overflow-hidden overflow-y-hidden'>   
            { 
                selectedReport?.timeline?.history?.map(
                    (item, index)=> 
                         {
                            if(item.object == 'startCase'){
                                startCase = true
                            }
                            if( !item.hideOnReport ){
                                return ( 
                                    <div key={'report-selected-'+index} className={` h-full w-full cursor-pointer bg-white hover:bg-[#0008] hover:text-[#fff]  flex flex-col items-center `}>
                                        <div  className={`text-center flex  items-center gap-0 justify-between h-full w-full flex items-between`}> 
                                            <div className='text-sm text-left flex  p-1 justify-start items-center w-4/6 font-[500] gap-4'>
                                                <span className='text-[#2aac9c] bg-[#0001] text-sm font-[600] p-1'>
                                                    {   !startCase && '--:--'} 

                                                    {   startCase &&  
                                                        timerFormat( new Date(item.time - selectedReport?.timeline?.startCase))
                                                    }
                                                     
                                                </span> 
                                                <div >
                                                    {
                                                        
                                                        <supr style={{color:item?.colorPrimario || "#000"}} className='text-xl'>
                                                            {  item.title }
                                                        </supr> 
                                                    }
                                                    {   item.value != undefined &&
                                                        <supr style={{color:item?.colorSecundario || "#000"}} className='text-sm font-[400]'>
                                                            {  item.value }
                                                        </supr> 
                                                    }
                                                </div> 
                                            </div> 
                                        </div> 
                                    </div>
                                )
                            }
                        }
                         
                ) 
            } 
            
        </div>
{/**
        <div className=' cursor-pointer w-full h-10 p-6 flex items-center justify-center text-2xl bg-[#0002]' onClick={handlerFullReport}>
                {
                    !minimized ? 
                    <div className='flex gap-10 items-center'>
                        <FontAwesomeIcon icon={faArrowUp} />
                        <span>Minimizar</span>
                        <FontAwesomeIcon icon={faArrowUp} />
                    </div>
                     :
                    <div className='flex gap-10 items-center'>
                        <FontAwesomeIcon icon={faArrowDown} /> 
                        <span>Mostrar todo</span>
                        <FontAwesomeIcon icon={faArrowDown} /> 
                    </div>
                }
        </div>
 * 
 */}

        </>
    )
}

export default HistoryText