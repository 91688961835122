
import React,{ createContext, useContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import { Home, Basic, Ovace, Advanced, Reports, BasicSupport, OvaceNew } from './pages'
import ReactGA from 'react-ga'

ReactGA.initialize('G-75FQ87LFHC')

import { GlobalContext, TimelineContext,ContextoReset } from './helpers/Context';
import { TimelineDefault } from './helpers/Constants';

const GlobalDefault = { 
  startCase : null
}


const App = () => {
  const [contextGolbal, setContextGolbal] = useState(GlobalDefault)
  
  const [contextTimeline, setContextTimeline] = useState(TimelineDefault)
  
  const ContextoDefault = { resetTimeline : () => setContextTimeline(TimelineDefault) }
  
  const [contextoReset, setContextReset] = useState(ContextoDefault)

  return (
     
    <GlobalContext.Provider value={contextGolbal}>
       <TimelineContext.Provider value={contextTimeline}>
        <ContextoReset.Provider value={contextoReset}>
            <BrowserRouter>
                <Routes>
                  <Route path="*" element={<Home />} />
                  <Route path="/" element={<Home />} /> 
                  <Route path="/basicOld" element={<Basic />} />
                  <Route path="/basic" element={<BasicSupport />} /> 
                  <Route path="/ovaceOld" element={<Ovace />} />
                  <Route path="/ovace" element={<OvaceNew />} />
                  <Route path="/advanced" element={<Advanced />} /> 
                  <Route path="/reports" element={<Reports />} /> 
                </Routes>
            </BrowserRouter>
          </ContextoReset.Provider>
       </TimelineContext.Provider>
    </GlobalContext.Provider>
 
  )
}




export default App