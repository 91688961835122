import { faClose, faStopwatch,faPencil,faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useContext,useRef } from "react";
import { isMobile } from "react-device-detect";
import GraphicalReport from "../GraphicalReport/GraphicalReport.";
import HistoryText from "../HistoryText/HistoryText";
import { iconInforme } from "../../helpers/ImagesCache";
import { ContextoReset, GlobalContext, TimelineContext } from '../../helpers/Context'
import ReactDOM from "react-dom"; 
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const HiddenRenderer = ({ children, onRendered }) => {
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.top = "-9999px";
    container.style.left = "-9999px"; 
  
    document.body.appendChild(container);
  
    React.useEffect(() => {
      ReactDOM.render(children, container, () => {
        onRendered(container);
      });
  
      return () => {
        ReactDOM.unmountComponentAtNode(container);
        document.body.removeChild(container);
      };
    }, [children]);
  
    return null;
  };


const DialogFinish = ({ selectedReport, callbackConfirm, callbackCancel, onUpdateContext }) => {
    const componentRef = useRef();

    const inputTextRef =  useRef()
    const [ editing, setEditing ] = useState( false )
    const [ isPdfExport, setIsPdfExport ] = useState( false )
    

    const handlerClose = () => {
        if(callbackCancel){ 
            callbackCancel()
        }
    }

    const handlerOnConfirm = () => {  
        if(callbackConfirm){ 
            callbackConfirm()
        }
    } 
       
    const handlerChangeText = () =>{  
        if(onUpdateContext){
            onUpdateContext( 'name', inputTextRef.current.value )
        } 
    }

    const handleDownloadPDF = ( ) =>{
       setIsPdfExport(!isPdfExport)
    };

    const PDFComponent = () => (
        <div className="my-4" style={{ padding: "20px", backgroundColor: "#fff" }}>
          <h1 className="my-4">
            INFORME DEL CASO - {selectedReport?.name}
            </h1>
          <ToRender />
        </div>
    );

   
    const handleSaveAsPDF = () => {
        const onRendered = (container) => {
          html2canvas(container, { scale: 1 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 190; // Ajustado a A4
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
            pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
            const nombre = inputTextRef.current.value
            pdf.save(  `${nombre}(simularcp - ${selectedReport?.type}).pdf`);
          });
        };
    
        // Renderizamos el componente en un contenedor oculto
        if(isPdfExport){  
            ReactDOM.render(
                <HiddenRenderer onRendered={onRendered}>
                <PDFComponent />
                </HiddenRenderer>,
                document.createElement("div")
            );
        }
      };

      const ToRender = () => {
        return (
            <div 
                ref={componentRef}
                className="flex flex-col w-full  bg-white"  
                style={{ width: isMobile ? '100%' : '100%', height: isMobile ? '100%' : '90%', padding: isMobile ? '10px' : '10px'}}
                >
                 
                <GraphicalReport   
                    hideName = { true }
                    selectedReport = { {
                        timeline: selectedReport
                    } }
                    historyReport = { true }
                    header = {true} 
                    minimized={false}
                /> 
            </div> 
        )
    }

    useEffect(()=>{
        if(isPdfExport){ 
            handleSaveAsPDF()
        }
    },[isPdfExport])
    return (<>
           
         <div className="pt-[10%] flex flex-col items-end justify-center fixed left-0 top-500 bg-[#0005] w-full h-[100%]" style={{ zIndex: 10001 }}>
            <div 
                ref={componentRef}
                className="flex flex-col w-full  bg-white"  
                style={{ width: isMobile ? '100%' : '100%', height: isMobile ? '100%' : '90%', padding: isMobile ? '10px' : '10px'}}
                >
                <div className=' z-[0] px-4  flex flex-col  bg-[#fff] flex w-full items-center justify-start gap-5 '>
                    <div className='flex w-full items-center justify-between gap-4 font-[600] text-[#0007] mx-10'>
                        <div className='flex w-full items-center gap-4'>
                        
                            <img src={iconInforme} alt={iconInforme} />
                            <span>INFORME DEL CASO</span> 
                        </div>
                        <div 
                            onClick={handlerClose}
                            className='flex gap-4 cursor-pointer items-center justify-center h-full w-10 text-2xl text-[red]' >
                            
                            x
                        </div>
                    </div> 
                </div> 
                <div className='w-full border-b-2 border-b-solid border-b-gay-500 pb-5 pt-5'>
                    <div className='text-xl font-[500] flex justify-between' > 
                        {
                              (
                                <>
                                    <input 
                                        ref={inputTextRef}
                                        type="text" 
                                        name="" 
                                        id="" 
                                        defaultValue={selectedReport?.name} 
                                        className='w-10/12 p-1'
                                        onFocus={()=> setEditing(true)}  
                                        disabled={!editing}
                                        onMouseLeave={()=> setEditing(false)} 
                                        onChange={handlerChangeText}
                                        /> 
                                     <button  
                                            className='rounded text-3xl w-10 h-10 '
                                            onClick={()=>{  
                                                setEditing(true)
                                                inputTextRef.current.focus() 
                                            }}
                                            >
                                            <FontAwesomeIcon  icon={faPencil}  />
                                        </button> 
                                    <button   
                                        className='border-solid border-gray-500 border-2 rounded text-3xl w-10 h-10'
                                        onClick={()=>{ 
                                            setEditing(false)
                                            handlerOnConfirm() 
                                        } }   >
                                        <FontAwesomeIcon  icon={faSave}  />
                                    </button> 
                                    
                                </>
                            ) 
                        }
                    </div>
                </div> 
                <div className="overflow-y-auto"> 
                    {
                        
                        <GraphicalReport   
                            hideName = { true }
                            selectedReport = { {
                                timeline: selectedReport
                            } }
                            historyReport = { true }
                            header = {true} 
                            minimized={false}
                        />
                    }
                </div>
            </div> 
            <div className="flex justify-center items-center w-full p-2 bg-[#456789] text-white cursor-pointer"> 
            
                <button onClick={handleDownloadPDF}>Guardar como PDF</button>
            </div>
         </div>
    </>)
}



export default DialogFinish