
import { faArrowRight, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
 
const ListItem = ({options,onClick,onDelete}) =>{
    const { name:title, createdAt:time, id } = options
    const [ status, setStatus ] = useState(null) 
    const [ values, setValues ] = useState([])  

    const handlerOnClick = () => {  
        if(onClick){ 
            console.log('mostrando el id:', id)
            onClick(id)
        }
    } 
    
    const handleDelete = (id) => {
        
        if(onDelete){
            onDelete(id)
        } 
    } 


    return (<>
        <div className={`flex gap-4  p-1 h-full w-full cursor-pointer bg-white hover:bg-[#0008] hover:text-[#fff] mt-[1%] items-center boder-b border-b-solid border-b-[#00055] border-b-2`}>
           { onDelete &&
            <div onClick={()=>handleDelete(id)} className='hover:bg-red-100 cursor-pointer hover:text-red-500 flex items-center justify-center text-2xl h-full w-10 '>
                <FontAwesomeIcon icon={faTrashAlt} />
            </div>
            }
            <div   onClick={handlerOnClick} className={`text-center flex  items-center gap-0 justify-between h-full w-full flex items-between`}> 
                <div className="text-sm text-left flex flex-col p-1 justify-center items-between w-4/6 font-[500]">
                    <span className='text-xl'>
                        {title}
                    </span>
                    <span className=''>
                        {time}
                    </span> 
                </div>
                <div className="flex items-center justify-center w-2-6 rounded bg-[#0000] w-[40px] h-[40px]">
                   
                    <FontAwesomeIcon icon={faArrowRight} />
                </div>
            </div> 
        </div>
    </>) 
}

export default ListItem