/**
* 
* @author Juan Rico
* @component NSTimer 
* @description  un boton con temporizador personalizable
* normalmente usado para gestionar el tiempo global
*/

//#region  Importaciones necesarias para el componente NSTimer
import React,{useState,useEffect} from "react" // Importa React y hooks.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' //importa componentes de fontawesome
import { faStopwatch } from '@fortawesome/free-solid-svg-icons' //importa iconos de fontawesome
import { timerFormat, completeTimerFormat } from "../../../helpers/helpers" //importar funciones de ayuda 
import { isMobile } from "react-device-detect"  // importacion de elementos responsive
//#endregion

const NSTimer = ({ 
    titleOn, //Titulo cuando su estado es activo
    titleOff, //Titulo cuando su estado esta inactivo - por defecto
    onCheckStart, //Accion callback para checkear el inicio,
    onCheckStop,//Accion callback para checkear la detencion,
    onStartTimer, //Accion callback al INICIAR el temporizador
    onStopTimer, //Accion callback al DETENER el temporizador
    milliseconds, //Activar la visualizacion de milisegundos en el temporizador
    forceDisable,  //Forzar la desactivacion la accion de este componente
    forceStart, //Forzar INICIAR el temporizador
    forceStop  //Forzar DETENER el temporizador
}) =>{ 

//#region ############## VARIABLES DE ESTADO GENERALES ##############/
    /**
     * Diferencia de tiempo
     * @type {Number}
     * @default null - mantiene el valor diferencia entre el timestamp inicial
     * y el timestamp actual para determinar el conteo relativo
    */
    const [diff, setDiff] = useState(null)

    /**
     * Tiempo incial 
     * @type {Number}
     * @default null - mantiene el valor inicial timestamp 
     * desde donde el cronometro comenzara a evaluar
    */
    const [initial, setInitial] = useState(null) 

     /**
     * Estado actual del contador
     * @type {boolean}
     * @default false - controlar el cambio de estado del 
     * componente cuando el contador ha iniciado 
    */
    const [start, setStart] = useState(false)
//#endregion

   
//#region ############## FUNCIONES HANDLE BASICAS ##############//

    /**
	 * Manejador del estado start para iniciarlo y tener un callback 
	 * @function handleToStart  
     * Se utiliza para iniciar el contador y accionar el callback onStartTimer
     * en caso de que se pase la prop
	 */
    const handleToStart =()=>{  
        setStart(true)
        setInitial(+new Date())
        if(onStartTimer){
            onStartTimer({
                startAt: +new Date(), 
                stopAt:  null, 
            })
        }
 
    }

    /**
	 * Manejador del estado start para detenerlo y tener un callback 
	 * @function handleToStop 
     * Se utiliza para detener el contador y accionar el callback onStopTimer
     * en caso de que se pase la prop
	 */
    const handleToStop =()=>{ 
        setStart(false) 
        if(onStopTimer){
            onStopTimer({
                startAt: initial, 
                stopAt:  initial + diff.getTime(), 
            })
        }
       //alert('se detuvo con el valor initial=' + initial)
    } 

    /**
	 * Manejador del estado start para iniciarlo y detenerlo sin tener un callback 
	 * @function handleOnClick  
     * Se utiliza para intercambiar el estado de la variable start 
     * de tal manera que pueda iniciar o detener el contador sin 
     * tener un callback asociado, solo las validaciones de forceStop y forceDisable
     * que determinan si estara o no activo el boton
	 */
    const handleOnClick = () => {  
        if(forceStop) return
        if(forceDisable) return 
        if(start){
            if(!onCheckStop){ 
                handleToStop()
            } else {
                onCheckStop({
                    startAt: +new Date(), 
                    stopAt:  null, 
                })
            }
        } else {
            if(!onCheckStart){ 
                handleToStart()
            } else {
                onCheckStart({
                    startAt: +new Date(), 
                    stopAt:  null, 
                })
            }
        }  

    } 

//#endregion  


//#region ############## FUNCIONES AVANZADAS ##############//
/**
	 * Manejador del estado start para iniciarlo y detenerlo sin tener un callback 
	 * @function onForceStart  
     * Se utiliza para intercambiar el estado de la variable start 
     * de tal manera que pueda iniciar o detener el contador sin 
     * tener un callback asociado, solo las validaciones de forceStop y forceDisable
     * que determinan si estara o no activo el boton
	 */
    const onForceStart = () => {   
        if(forceStart){
            handleToStart()  
        }  
    } 
    /**
	 * Contador de milisegundos. 
	 * @function tick 
	 * Se encarga de contar y evaluar cada milisegundo 
     * y setea la diferencia de tiempos entre el valor
     * inicial y el actual
	 */
    const tick = () => {
        if(forceStop) return
        const temporalDate = +new Date()  
        setDiff(new Date(temporalDate - initial ))
    } 
//#endregion  

//#region ############## USEEFFECTS ##############//
    // Hook que se ejecuta al montar el componente 
    // registramos los ticks cada vez que inital o start cambian.
    useEffect(()=>{
        if(forceStop) return 
        if(initial && start){
            requestAnimationFrame(tick)
        }  
    },[initial,start])
    
    // Hook que se ejecuta al montar el componente 
    // registramos los ticks cada vez que diff o start cambian.
    useEffect(()=>{
        if(forceStop) return
        if(diff && start){
            requestAnimationFrame(tick)
        }  
    },[diff,start]) 

    // Hook que se ejecuta al montar el componente 
    // registramos los ticks cada vez que diff o start cambian.
    useEffect(()=>{ 
        if(onCheckStart && !start && forceStart){
            onForceStart()
        }
    },[forceStart]) 

// Hook que se ejecuta al montar el componente 
    // registramos los ticks cada vez que diff o start cambian.
    useEffect(()=>{ 
        if(onCheckStop && start && forceStop && !forceStart){
            handleToStop()
        }
    },[forceStop, start, forceStart]) 
 


    
//#endregion  

    return (<>
        <div style={{
                border:start ? "solid 2px #0BAC9D":"solid 2px #0BAC9D00",
                width:isMobile?'180px':'220px',
                opacity: forceDisable ? '50%' : '100%'
            }}
            className="flex cursor-pointer shadow bg-white rounded items-center border  h-[46px] border-2 border-[#0BAC9D]"
            onClick={handleOnClick}
            >
            <div className="flex p-0 items-center justify-between gap-1 p-1 w-full">
                <div className="flex  items-center text-sm min-w-6/12 w-6/12 max-w-6/12"> 
                    
                    {
                    start? 
                        titleOn?
                            titleOn
                        :
                            'titulo' 
                    : 
                        titleOff?
                            titleOff
                        :
                            'titulo'
                    }
                </div>
                <div style={{color:start?"white":"black",background:start?"#0BAC9D":"#00ffaa22", width: milliseconds ? '60%': '50%'}} className="flex items-center rounded gap-2  p-1 justify-between min-w-6/12 w-6/12 max-w-6/12">
                    <FontAwesomeIcon icon={faStopwatch} />
                    <span style={{
                        width:isMobile?'60px':'80px'
                    }}
                        className="text-xl flex items-center w-[80px]">
                        { 
                        milliseconds ? 
                            isMobile? 
                                timerFormat(diff) : 
                                completeTimerFormat(diff) : 
                            timerFormat(diff) }
                         
                    </span>
                </div>
            </div>
        </div>
    </>) 
}

export default NSTimer